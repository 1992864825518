import { toast } from 'react-toastify';
// eslint-disable-next-line import/no-extraneous-dependencies
import { debounce } from 'lodash';
import { useCallback } from 'react';
import ProductNumericInput, {
    ILocalItemWithQuantityProductNumericInput,
    IPropsProductNumericInput,
} from '../product-numeric-input';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { useOnlineStore } from '../../../../contexts/onlineStoreContext';

interface IInvoiceItens {
    productErpCode: string;
    quantity: number;
    totalValue: number;
}

interface props extends IPropsProductNumericInput {
    allItens: IInvoiceItens[];
    cartId: number;
    productType: 'BF' | 'MP';
    setLoading?: (loading: boolean) => void;
}
export default function CartProductNumericInput({
    localItem,
    onChange,
    quantityValue,
    allItens,
    cartId,
    productType,
    setLoading,
    disabled,
}: props) {
    const { loadCartDebounce, setItems } = useOnlineStore();
    async function updateCart(data: ILocalItemWithQuantityProductNumericInput) {
        await InvoiceHttpService.updateItemQuantity(
            cartId ?? 0,
            data.B1_COD,
            data.quantity,
            data.boardingDate,
        );
        loadCartDebounce();
        if (setLoading) {
            setLoading(false);
        }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSave = useCallback(
        debounce((nextValue) => updateCart(nextValue), 1000),
        [],
    );

    const isCable = () => localItem.B1_COD.substring(0, 4) === '1020';
    const getStep = () => (isCable() ? 10 : 1);

    function rulesToUpdateCart(
        data: ILocalItemWithQuantityProductNumericInput,
    ) {
        if (
            productType === 'BF' &&
            ((allItens.length > 0 && data.quantity > 0) || data.quantity > 1)
        ) {
            return {
                error: true,
                message:
                    'Nâo é possivel adicionar mais de um produto promocional!',
            };
        }
        return { error: false, message: '' };
    }

    function defineQuantity(data: ILocalItemWithQuantityProductNumericInput) {
        if (!isCable()) {
            return data.quantity;
        }

        if (data.quantity % 10 !== 0) {
            // eslint-disable-next-line no-param-reassign
            data.quantity = Math.ceil(data.quantity / 10) * 10;
            return data.quantity;
        }
        return 0;
    }

    function handleChange(data: ILocalItemWithQuantityProductNumericInput) {
        const rules = rulesToUpdateCart(data);
        defineQuantity(data);
        if (rules.error) {
            toast.error(rules.message);
            return;
        }
        if (setLoading) {
            setLoading(true);
        }
        setItems((p: ILocalItemWithQuantityProductNumericInput[]) => {
            const productExistIndex = p.findIndex(
                (value) => value.B1_COD === data.B1_COD,
            );
            if (productExistIndex !== -1) {
                // eslint-disable-next-line no-param-reassign
                p[productExistIndex] = data;
                return p;
            }
            return [...p, data];
        });
        debouncedSave(data);
        onChange(data);
        // eslint-disable-next-line consistent-return
        return data.quantity;
    }
    return (
        <ProductNumericInput
            localItem={localItem}
            disabled={disabled}
            onChange={(data) => {
                return handleChange(data);
            }}
            quantityValue={quantityValue ?? 0}
            quantityPerChange={getStep}
        />
    );
}
