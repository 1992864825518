import {
    Col,
    Image,
    Nav,
    Navbar,
    OverlayTrigger,
    Popover,
    Row,
} from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import { PiList } from 'react-icons/pi';
import apps from '../../assets/icons/apps.svg';
import budget from '../../assets/icons/budget.svg';
import house from '../../assets/icons/house.svg';
import invoices from '../../assets/icons/invoices.svg';
import store from '../../assets/icons/store.svg';
import { Role } from '../../constants/role';
import { useAuth } from '../../contexts/authContext';
import { useOnlineStore } from '../../contexts/onlineStoreContext';
import { SellerValues, useSeller } from '../../contexts/sellerContext';
import {
    dashboardListRoute,
    invoicesListRoute,
    listGeneratorsRoute,
    pointGeneralInfoRoute,
    pointStatementRoute,
    pointStoreRoute,
    userProfileRoute,
    wishlistRoute,
} from '../../routes/config';
import { budgetsListRoute } from '../../routes/config/budgets.route';
import { creditCardAnalysisListRoute } from '../../routes/config/creditCardAnalysis.route';
import userHasRoles from '../../utils/userHasRoles';
import Responsable from '../OnlineStore/Responsable';
import TopCart from '../OnlineStore/TopCart';
import NotificationsDropdown from './NotificationsDropdownMenu';
import UserDropdownMenu from './UserDropdownMenu';
import {
    Icon,
    IconContainer,
    OptionContainer,
    OptionText,
    StyledAlignDiv,
    StyledButton,
    StyledNavbar,
    StyledNavbarBrand,
    StyledNavbarItemDivider,
} from './styles';
import pages from '../../constants/pages';

interface Props {
    small: boolean;
    toggle: () => void;
}

const NavbarHeader = (props: Props) => {
    const { small: isSmallScreen, toggle: handleToggle } = props;

    const { user } = useAuth();
    const { seller }: SellerValues = useSeller();

    const { pathname } = window.location;
    const { cart } = useOnlineStore();
    const history = useHistory();
    const isFinancial = userHasRoles(user, [Role.Financial]);
    const isIntegrator = userHasRoles(user, [Role.Integrator]);
    const isAdministrator = userHasRoles(user, [Role.Administrator]);
    const isCommercialOrAdministrator = userHasRoles(user, [
        Role.Commercial,
        Role.Administrator,
        Role.CommercialSupervisor,
        Role.CustomerSuccess,
    ]);
    const isLogistics = userHasRoles(user, [Role.Logistics, Role.Production]);
    const isPointsAdministrator = userHasRoles(user, [
        Role.PointsAdministrator,
    ]);
    const isManager = userHasRoles(user, [Role.Manager]);
    const isContributor = userHasRoles(user, [Role.Contributor]);

    const activeMenuStyles: any = {
        background: 'rgba(255, 255, 255, 0.18)',
    };

    const userAllowedPages = pages.filter((page) =>
        page.roles.length > 0 ? userHasRoles(user, page.roles) : true,
    );

    const userTabPermissions = {
        dashboard: true,
        logistics: isLogistics,
        isAdministrator,
        onlineStore:
            isCommercialOrAdministrator ||
            isIntegrator ||
            isManager ||
            isContributor,
        invoice:
            isCommercialOrAdministrator ||
            isIntegrator ||
            isFinancial ||
            isManager ||
            isContributor,
        budget:
            isCommercialOrAdministrator ||
            isIntegrator ||
            isManager ||
            isContributor,
        point:
            isCommercialOrAdministrator ||
            isPointsAdministrator ||
            isManager ||
            (!isContributor && !isFinancial && user?.seller?.pointsEnabled),
        pointOpensManagement:
            (isAdministrator && seller && !seller?.pointsEnabled) ||
            (!isContributor &&
                isPointsAdministrator &&
                !isCommercialOrAdministrator),
        creditCardAnalysis: isAdministrator || isFinancial,
    };

    if (isSmallScreen) {
        return (
            <>
                <StyledNavbar
                    bg="dark"
                    variant="dark"
                    className="ma-0 pa-0 d-flex justify-content-between"
                >
                    <StyledAlignDiv>
                        <StyledButton onClick={handleToggle}>
                            <PiList size={30} />
                        </StyledButton>
                    </StyledAlignDiv>

                    <StyledNavbarBrand
                        as={Link}
                        to={dashboardListRoute.path}
                        className="ml-2"
                    >
                        nexen
                    </StyledNavbarBrand>

                    <StyledAlignDiv />
                </StyledNavbar>
            </>
        );
    }

    return (
        <>
            <StyledNavbar bg="dark" variant="dark" className="ma-0 pa-0">
                <Navbar.Brand
                    as={Link}
                    to={dashboardListRoute.path}
                    style={{
                        fontSize: '28px',
                        fontWeight: 700,
                        width: '200px',
                        fontFamily: 'Branding',
                    }}
                    className="ml-2 d-block"
                >
                    nexen
                </Navbar.Brand>

                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="mr-auto">
                        {userTabPermissions.dashboard && (
                            <Nav.Link
                                as={Link}
                                to={dashboardListRoute.path}
                                className="text-center"
                                style={{
                                    ...(pathname === dashboardListRoute.path
                                        ? activeMenuStyles
                                        : null),
                                    ...{ width: '105px', color: '#ffffff' },
                                }}
                            >
                                <Image src={house} style={{ width: '22px' }} />
                                <span className="d-block mt-1">Início</span>
                            </Nav.Link>
                        )}

                        {userTabPermissions.onlineStore && (
                            <Nav.Link
                                onClick={async () => {
                                    history.push(listGeneratorsRoute.path);
                                }}
                                className="text-center"
                                style={{
                                    ...(pathname !== dashboardListRoute.path &&
                                    !pathname.startsWith(
                                        invoicesListRoute.path,
                                    ) &&
                                    !pathname.startsWith(
                                        budgetsListRoute.path,
                                    ) &&
                                    !pathname.startsWith(
                                        creditCardAnalysisListRoute.path,
                                    ) &&
                                    !pathname.startsWith(
                                        pointGeneralInfoRoute.path,
                                    ) &&
                                    !pathname.startsWith(
                                        pointStatementRoute.path,
                                    ) &&
                                    !pathname.startsWith(
                                        pointStoreRoute.path,
                                    ) &&
                                    !pathname.startsWith(wishlistRoute.path) &&
                                    !pathname.startsWith(userProfileRoute.path)
                                        ? activeMenuStyles
                                        : null),
                                    ...{ width: '105px', color: '#ffffff' },
                                }}
                            >
                                <Image src={store} style={{ width: '22px' }} />

                                <span className="d-block mt-1">
                                    Loja Online
                                </span>
                            </Nav.Link>
                        )}

                        {userTabPermissions.invoice && (
                            <Nav.Link
                                as={Link}
                                to={invoicesListRoute.path}
                                className="text-center"
                                style={{
                                    ...(pathname.startsWith(
                                        invoicesListRoute.path,
                                    )
                                        ? activeMenuStyles
                                        : null),
                                    ...{ width: '105px', color: '#ffffff' },
                                }}
                            >
                                <Image
                                    src={invoices}
                                    style={{ width: '22px' }}
                                />

                                <span className="d-block mt-1">
                                    Meus Pedidos
                                </span>
                            </Nav.Link>
                        )}

                        {userTabPermissions.budget && (
                            <Nav.Link
                                as={Link}
                                to={budgetsListRoute.path}
                                className="text-center"
                                style={{
                                    ...(pathname.startsWith(
                                        budgetsListRoute.path,
                                    )
                                        ? activeMenuStyles
                                        : null),
                                    ...{ width: '105px', color: '#ffffff' },
                                }}
                            >
                                <Image src={budget} style={{ width: '22px' }} />

                                <span className="d-block mt-1">Orçamentos</span>
                            </Nav.Link>
                        )}

                        {/* {userTabPermissions.point && (
                            <Nav.Link
                                as={Link}
                                to={
                                    userTabPermissions.pointOpensManagement
                                        ? pointManagementRoute.path
                                        : pointGeneralInfoRoute.path
                                }
                                className="text-center"
                                style={{
                                    ...(pathname.startsWith(
                                        pointGeneralInfoRoute.path,
                                    ) ||
                                    pathname.startsWith(
                                        pointStatementRoute.path,
                                    ) ||
                                    pathname.startsWith(wishlistRoute.path) ||
                                    pathname.startsWith(pointStoreRoute.path)
                                        ? activeMenuStyles
                                        : null),
                                    ...{ width: '105px', color: '#ffffff' },
                                }}
                            >
                                <Image src={point} style={{ width: '22px' }} />

                                <span className="d-block mt-1">
                                    Pontos Nexen
                                </span>
                            </Nav.Link>
                        )} */}

                        {userTabPermissions.creditCardAnalysis && (
                            <Nav.Link
                                as={Link}
                                to={creditCardAnalysisListRoute.path}
                                className="text-center"
                                style={{
                                    ...(pathname.startsWith(
                                        creditCardAnalysisListRoute.path,
                                    )
                                        ? activeMenuStyles
                                        : null),
                                    ...{ width: '170px', color: '#ffffff' },
                                }}
                            >
                                <Image src={budget} style={{ width: '22px' }} />

                                <span className="d-block mt-1">
                                    Análise Cartão de Crédito
                                </span>
                            </Nav.Link>
                        )}
                    </Nav>

                    <Nav className="pa-0 ma-0">
                        <Responsable />

                        <div> {cart?.status === 'CART' && <TopCart />}</div>

                        <NotificationsDropdown />

                        <StyledNavbarItemDivider className="ml-3 mr-3" />

                        <OverlayTrigger
                            trigger="click"
                            placement="bottom"
                            rootClose
                            overlay={
                                <Popover
                                    id="popover-contained"
                                    className="p-3 w-300"
                                    style={{ maxWidth: '420px' }}
                                >
                                    <Col>
                                        <Row>
                                            {userAllowedPages.map(
                                                (page, index) => (
                                                    <OptionContainer
                                                        href={page.url}
                                                        key={index}
                                                    >
                                                        <IconContainer>
                                                            <Icon
                                                                className={
                                                                    page.icon
                                                                }
                                                            />
                                                        </IconContainer>
                                                        <OptionText>
                                                            {page.name}
                                                        </OptionText>
                                                    </OptionContainer>
                                                ),
                                            )}
                                        </Row>
                                    </Col>
                                </Popover>
                            }
                        >
                            <Image
                                style={{ cursor: 'pointer' }}
                                src={apps}
                                width={30}
                                className="mr-2 ml-2"
                            />
                        </OverlayTrigger>

                        <UserDropdownMenu />
                    </Nav>
                </Navbar.Collapse>
            </StyledNavbar>
        </>
    );
};

export default NavbarHeader;
