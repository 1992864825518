/* eslint-disable react/self-closing-comp */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable prefer-const */
/* eslint-disable prefer-template */
import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Form,
    InputGroup,
    Modal,
    Row,
    Spinner,
    Table,
} from 'react-bootstrap';
import ImageGallery from 'react-image-gallery';
import MaskedInput from 'react-text-mask';
import {
    StyledTitle,
    StyledSubtitle,
    StyledText,
    StyledDetailValue,
} from './styles';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { IMG_SRC_URL } from '../../../config/webservice';
import NumericInput from '../../NumericInput';
import FreightsHttpService from '../../../services/http/freights-http';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { cepMask } from '../../../utils/masks';
import ProductHttpService from '../../../services/http/product.http';

interface Product {
    BM_GRUPO: string;
    BM_DESC: string;
    B1_COD: string;
    B1_DESC: string;
    VLR_TOT: string;
    DETALHES: String;
    FT: any;
    IMG_CAPA: string;
    SALDO_DISP: number;
    quantity: number;
    INVOICE_ITEM_ID: number;
    boardingDate: string;
}

const Detail = (props: any) => {
    const [product, setProduct] = useState({} as Product);
    const [loading, setLoading] = useState(false);
    const [addressCep, setAddressCep] = useState('');
    const [deliveryDeadline, setDeliveryDeadline] = useState(null);
    const [loadingDeliveryDeadline, setLoadingDeliveryDeadline] =
        useState(false);

    const [productPrice, setProductPrice] = useState('');
    const { setViewType, setSelectedProduct, selectedProductId, cart } =
        useOnlineStore();

    useEffect(() => {
        if (!product?.VLR_TOT) {
            return;
        }

        const re = new RegExp('^-?\\d+(?:.\\d{0,2})?');
        const value = product.VLR_TOT.match(re)?.[0] || '0';

        setProductPrice(
            parseFloat(value).toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
            }),
        );
    }, [product]);

    useEffect(
        () => () => {
            setSelectedProduct({});
        },
        [setSelectedProduct, setViewType],
    );

    const loadProducts = async () => {
        if (selectedProductId) {
            setLoading(true);

            const response = await ProductHttpService.show(selectedProductId);

            setLoading(false);

            setProduct(response.data.data.product[0]);
        }
    };

    const getDeliveryDeadline = async () => {
        setLoadingDeliveryDeadline(true);

        try {
            const response = await FreightsHttpService.readOne(addressCep);

            setDeliveryDeadline('');

            if (response && response.data && response.data.deadline) {
                setDeliveryDeadline(response.data.deadline);
            } else {
                setDeliveryDeadline(0);
            }
        } catch (error) {
            setDeliveryDeadline(0);
        }

        setLoadingDeliveryDeadline(false);
    };

    useEffect(() => {
        loadProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedProductId]);

    return (
        <>
            <Modal
                className={'modal-dialog-scrollable'}
                {...props}
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        className={'ml-3 mr-3'}
                        id="contained-modal-title-vcenter"
                    >
                        Detalhes do Produto
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={'w-100 mr-3'}>
                    {loading ? (
                        <Spinner
                            style={{
                                marginLeft: '48%',
                            }}
                            animation={'border'}
                        />
                    ) : (
                        <div>
                            {!product ? (
                                <div
                                    className={'mx-auto'}
                                    style={{
                                        marginTop: '20%',
                                    }}
                                >
                                    <StyledTitle decoration={'none'}>
                                        Sem detalhes para exibição
                                    </StyledTitle>
                                </div>
                            ) : (
                                <div className={'ml-3'}>
                                    <Row>
                                        <h6
                                            className={'ml-2'}
                                            style={{
                                                borderBottom:
                                                    '2px solid #F2994A',
                                                paddingBottom: '10px',
                                                color: '#F2994A',
                                                fontWeight: 600,
                                                fontSize: 13,
                                            }}
                                        >
                                            Ficha Técnica
                                        </h6>
                                    </Row>
                                    <Row>
                                        <Col xl={8} lg={8} md={12}>
                                            <br />
                                            <Row>
                                                <ImageGallery
                                                    thumbnailPosition={'left'}
                                                    showNav={false}
                                                    showFullscreenButton={false}
                                                    showPlayButton={false}
                                                    items={[
                                                        {
                                                            original:
                                                                product?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                                    : '',
                                                            thumbnail:
                                                                product?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                                    : '',
                                                        },
                                                        {
                                                            original:
                                                                product?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                                    : '',
                                                            thumbnail:
                                                                product?.IMG_CAPA
                                                                    ? `${IMG_SRC_URL}/${product.IMG_CAPA}`
                                                                    : '',
                                                        },
                                                    ]}
                                                />
                                            </Row>

                                            <div className={'mt-3 ml-3'}>
                                                <Row>
                                                    <StyledTitle
                                                        decoration={'none'}
                                                        style={{
                                                            color: '#707070',
                                                        }}
                                                    >
                                                        {product.B1_DESC}
                                                    </StyledTitle>
                                                </Row>
                                                <br />
                                                <Row>
                                                    <StyledSubtitle>
                                                        Descrição
                                                    </StyledSubtitle>
                                                </Row>
                                                <Row>
                                                    <StyledText
                                                        style={{ width: '60%' }}
                                                    >
                                                        {product.DETALHES}
                                                    </StyledText>
                                                </Row>
                                                <br />
                                            </div>
                                        </Col>
                                        <Col className={'ml-n5 mr-4'}>
                                            <StyledDetailValue>
                                                {productPrice}
                                            </StyledDetailValue>
                                            <Form.Group
                                                style={{ width: '40%' }}
                                            >
                                                <Form.Label
                                                    style={{
                                                        fontSize: 16,
                                                        color: '#707070',
                                                    }}
                                                >
                                                    Quantidade
                                                </Form.Label>
                                                <div>
                                                    <NumericInput
                                                        disabled={false}
                                                        tableItem={product}
                                                        item={{
                                                            status: InvoiceStatus.Cart,
                                                            id: product.INVOICE_ITEM_ID,
                                                            productErpCode:
                                                                product.B1_COD,
                                                            productName:
                                                                product.B1_DESC,
                                                            groupCode:
                                                                product?.BM_GRUPO,
                                                            productCategory:
                                                                product.BM_DESC,
                                                            unitValue:
                                                                product.VLR_TOT,
                                                            invoiceId: cart?.id,
                                                            availability:
                                                                product?.boardingDate,
                                                        }}
                                                    />
                                                </div>
                                            </Form.Group>
                                            <Row>
                                                <StyledSubtitle
                                                    style={{ marginLeft: 15 }}
                                                >
                                                    Calcular prazo
                                                </StyledSubtitle>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <Form
                                                        className={'ml-3 mb-3'}
                                                        style={{ height: 50 }}
                                                    >
                                                        <InputGroup
                                                            size="lg"
                                                            className="mb-5"
                                                        >
                                                            <Form.Control
                                                                type="text"
                                                                name="addressCep"
                                                                onChange={(
                                                                    event,
                                                                ) =>
                                                                    setAddressCep(
                                                                        event
                                                                            .target
                                                                            .value,
                                                                    )
                                                                }
                                                                style={{
                                                                    border: '1px solid #E0E0E0',
                                                                }}
                                                                as={MaskedInput}
                                                                mask={cepMask}
                                                                placeholder="Digite o CEP"
                                                            />
                                                        </InputGroup>
                                                    </Form>
                                                    <Button
                                                        size="lg"
                                                        className={
                                                            'd-block ml-1 mb-3'
                                                        }
                                                        onClick={
                                                            getDeliveryDeadline
                                                        }
                                                    >
                                                        <i className="fas fa-search"></i>
                                                    </Button>
                                                </div>
                                                <div
                                                    style={{ display: 'block' }}
                                                >
                                                    {loadingDeliveryDeadline &&
                                                        'Carregando...'}
                                                    {!!(
                                                        !loadingDeliveryDeadline &&
                                                        deliveryDeadline
                                                    ) && (
                                                        <span className="ml-3">
                                                            {deliveryDeadline}{' '}
                                                            dias úteis
                                                        </span>
                                                    )}
                                                    {!!(
                                                        !loadingDeliveryDeadline &&
                                                        deliveryDeadline === 0
                                                    ) &&
                                                        'Prazo de entrega indisponível'}
                                                </div>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <Row className={'ml-1 mr-3'}>
                                        <StyledSubtitle>
                                            Ficha Técnica
                                        </StyledSubtitle>
                                    </Row>
                                    <Row className={'ml-3 mr-3'}>
                                        <Table
                                            style={{
                                                width: '100%',
                                            }}
                                            striped
                                        >
                                            <thead></thead>
                                            <tbody>
                                                {product.FT &&
                                                    product.FT.map(
                                                        (
                                                            item: any,
                                                            index: any,
                                                        ) => (
                                                            <tr key={index}>
                                                                <td>{`${item.ATRIB} - ${item.VALOR}`}</td>
                                                            </tr>
                                                        ),
                                                    )}
                                            </tbody>
                                        </Table>
                                    </Row>
                                </div>
                            )}
                        </div>
                    )}
                </Modal.Body>

                <Modal.Footer className={'ml-3 mr-3 mb-2'}></Modal.Footer>
            </Modal>
        </>
    );
};

export default Detail;
