import { useRef, useState } from 'react';
import { Button, Col, Row, Spinner, Table } from 'react-bootstrap';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import BaseLayout from '../../../components/BaseLayout';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import InvoiceAttachmentTableRow from '../../../components/Invoices/InvoiceAttachments/InvoiceAttachmentTableRow';
import ConfirmPaymentInvoiceModal from '../../../components/OnlineStore/Invoice/ConfirmPaymentModal';
import InvoiceAttachmentStatus from '../../../constants/InvoiceAttachmentStatus';
import { InvoiceStatus } from '../../../constants/invoiceStatus';
import { NotificationIcon } from '../../../constants/notificationIcon';
import { rowsPerPage } from '../../../constants/pagination';
import { PaymentMethod } from '../../../constants/paymentMethod';
import { Role } from '../../../constants/role';
import { useAuth } from '../../../contexts/authContext';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import useInvoiceAttachmentPageAccess from '../../../hooks/useInvoiceAttachmentPageAccess';
import { invoiceAttachmentsNewRoute } from '../../../routes/config/invoices.route';
import InvoiceHttpService from '../../../services/http/invoice-http';
import InvoiceAttachment from '../../../shared/interfaces/invoiceAttachment.interface';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import { formatCurrency } from '../../../utils/strings';
import userHasRoles from '../../../utils/userHasRoles';
import { StyledPageTitle } from '../../CreditCardAnalysis/styles';
import { StyledTotalizer, StyledTotalizersRow } from './styles';
import handleResponseError from '../../../utils/handleResponseError';

interface FindInvoiceAttachmentsParams {
    pageParam?: number;
}

interface FindInvoiceAttachmentsResponse {
    data: InvoiceAttachment[];
    currentPage: number;
    total: number;
}

export default function List() {
    const { id: invoiceId } = useParams<{ id: string }>();

    const history = useHistory();

    const invoiceQuery = useInvoiceAttachmentPageAccess(invoiceId);

    const [attachmentOnDeleteProcess, setAttachmentOnDeleteProcess] = useState<
        number | null
    >(null);

    const [
        attachmentOnStatusUpdateProcess,
        setAttachmentOnStatusUpdateProcess,
    ] = useState<number | null>(null);

    const closeModalDelete = () => setAttachmentOnDeleteProcess(null);
    const closeModalStatusUpdate = () =>
        setAttachmentOnStatusUpdateProcess(null);

    const validateCanAddAttachmentsQuery = useQuery({
        queryKey: ['can-add-attachments', invoiceId],
        queryFn: async () => {
            const response = await InvoiceHttpService.validateCanAddAttachments(
                +invoiceId,
            );
            return response.data;
        },
        onError: (error: any) => {
            return handleResponseError(
                error,
                'Erro ao verificar se pode anexar comprovantes no pedido',
            );
        },
        onSuccess: async (data) => {
            console.log(data);
        },
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        refetchOnReconnect: false,
        retry: false,
    });

    const findInvoiceAttachments = async ({
        pageParam = 0,
    }: FindInvoiceAttachmentsParams): Promise<FindInvoiceAttachmentsResponse> => {
        const {
            data: { data, total },
        } = await InvoiceHttpService.getAttachments(invoiceId, {
            skip: pageParam,
            take: rowsPerPage,
        });

        return {
            data,
            currentPage: pageParam,
            total,
        };
    };

    const attachmentsQuery = useInfiniteQuery(
        ['attachments', invoiceId],
        findInvoiceAttachments,
        {
            getNextPageParam: ({ currentPage, total }) =>
                total >= rowsPerPage && currentPage + 1,
            staleTime: 300000,
            refetchOnWindowFocus: false,
            refetchOnMount: 'always',
            enabled: Boolean(invoiceId && invoiceQuery.data),
        },
    );

    const target = useRef();

    useIntersectionObserver({
        target,
        onIntersect: attachmentsQuery.fetchNextPage,
        enabled: attachmentsQuery.hasNextPage,
    });

    const queryIsLoading =
        invoiceQuery.isLoading ||
        attachmentsQuery.isLoading ||
        attachmentsQuery.isFetchingNextPage ||
        !attachmentsQuery.isFetched ||
        validateCanAddAttachmentsQuery.isLoading;

    const goToAddAttachmentPage = () =>
        history.push(invoiceAttachmentsNewRoute.build({ id: invoiceId }));

    const sendToErpMutation = useMutation({
        mutationFn: async () =>
            InvoiceHttpService.updateAttachmentStatus(
                +invoiceId,
                attachmentOnStatusUpdateProcess,
            ),
        onSuccess: async () => {
            await attachmentsQuery.refetch();

            toast.success('Comprovante enviado ao ERP com sucesso');
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao enviar o comprovante ao ERP');
        },
    });

    const removeAttachmentMutation = useMutation({
        mutationFn: async () => {
            await InvoiceHttpService.removeAttachment(
                invoiceId,
                attachmentOnDeleteProcess,
            );
        },
        onSuccess: async () => {
            await attachmentsQuery.refetch();

            toast.success('Comprovante removido com sucesso');
        },
        onError: (error: any) => {
            if (isFriendlyHttpError(error)) {
                toast.error(error.message);

                return;
            }

            toast.error('Ocorreu um erro ao remover o comprovante');
        },
    });

    const invoiceAttachments: InvoiceAttachment[] =
        attachmentsQuery.data?.pages.reduce(
            (attachments, page) => attachments.concat(page.data),
            [],
        );

    const totalSent =
        invoiceAttachments?.reduce((accumulator, attachment) => {
            if (attachment.status === InvoiceAttachmentStatus.Sent) {
                return accumulator + (attachment.voucherAmount || 0);
            }

            return accumulator;
        }, 0) || 0;

    const [showConfirmPaymentInvoiceModal, setShowConfirmPaymentInvoiceModal] =
        useState(false);

    const invoice = invoiceQuery.data;

    const { user } = useAuth();

    const isAdministratorOrFinancial = userHasRoles(user, [
        Role.Administrator,
        Role.Financial,
    ]);

    const releaseShippingMethods = [
        PaymentMethod.Financing,
        PaymentMethod.Deposit,
        PaymentMethod.Others,
    ];

    const releaseShippingStatuses = [
        InvoiceStatus.InvoiceApproved,
        InvoiceStatus.InvoiceBillingErp,
    ];

    const canReleaseShipping =
        isAdministratorOrFinancial &&
        releaseShippingMethods.includes(invoice?.paymentMethod) &&
        releaseShippingStatuses.includes(invoice?.status);

    return (
        <BaseLayout>
            <Row className="header align-items-center p-2 pt-3 pb-3">
                <Col>
                    <StyledPageTitle style={{ margin: 0 }}>
                        {`Comprovantes do pedido Nº ${invoiceId}`}
                    </StyledPageTitle>
                </Col>
                <Col>
                    <Button
                        style={{ width: '12rem' }}
                        onClick={goToAddAttachmentPage}
                        className="float-right pl-4 pr-4 ml-2 mb-2"
                        disabled={!validateCanAddAttachmentsQuery.data}
                    >
                        Adicionar comprovante
                    </Button>
                    {canReleaseShipping && (
                        <Button
                            style={{ width: '12rem' }}
                            onClick={() => {
                                setShowConfirmPaymentInvoiceModal(true);
                            }}
                            className="float-right pl-4 pr-4"
                        >
                            Liberar expedição
                        </Button>
                    )}
                </Col>
            </Row>
            <StyledTotalizersRow className="mt-4 ml-2 mr-2">
                <StyledTotalizer color="black">
                    Total do pedido: {formatCurrency(invoice?.totalInvoice)}
                </StyledTotalizer>
                <StyledTotalizer color="green">
                    Total enviado ao ERP: {formatCurrency(totalSent)}
                </StyledTotalizer>
                <StyledTotalizer color="red">
                    Saldo à enviar:{' '}
                    {formatCurrency((invoice?.totalInvoice || 0) - totalSent)}
                </StyledTotalizer>
            </StyledTotalizersRow>
            <Row className="mt-4">
                <Col className="ml-2 mr-2">
                    <Table bordered hover size="sm" className="text-center">
                        <thead>
                            <tr>
                                <th>NÚMERO</th>
                                <th>VALOR</th>
                                <th>DATA</th>
                                <th>DADOS BANCÁRIOS</th>
                                <th>STATUS</th>
                                <th>AÇÕES</th>
                            </tr>
                        </thead>
                        <tbody>
                            {invoice?.paymentMethod &&
                                invoiceAttachments?.map(
                                    (invoiceAttachment: InvoiceAttachment) => (
                                        <InvoiceAttachmentTableRow
                                            key={invoiceAttachment.id}
                                            paymentMethod={
                                                invoice.paymentMethod
                                            }
                                            invoiceAttachment={
                                                invoiceAttachment
                                            }
                                            onRemove={
                                                setAttachmentOnDeleteProcess
                                            }
                                            onSendToErp={
                                                setAttachmentOnStatusUpdateProcess
                                            }
                                        />
                                    ),
                                )}
                        </tbody>
                    </Table>
                </Col>
                <Col md={12} className="text-center" ref={target}>
                    {queryIsLoading ? (
                        <Col md={12} className="text-center">
                            <Spinner animation="border" />
                        </Col>
                    ) : (
                        !invoiceAttachments?.length && (
                            <Col md={12} className="text-center">
                                <strong style={{ color: '#adadad' }}>
                                    Nenhum comprovante encontrado
                                </strong>
                            </Col>
                        )
                    )}
                </Col>
            </Row>
            <ConfirmationDialog
                show={Boolean(attachmentOnDeleteProcess)}
                onHide={closeModalDelete}
                onConfirm={removeAttachmentMutation.mutateAsync}
                title="Remover comprovante"
                text="Deseja remover o comprovante anexado ao pedido?"
                icon={NotificationIcon.Info}
            />
            <ConfirmationDialog
                show={Boolean(attachmentOnStatusUpdateProcess)}
                onHide={closeModalStatusUpdate}
                onConfirm={sendToErpMutation.mutateAsync}
                title="Enviar ao ERP"
                text="Você tem certeza que deseja enviar o comprovante ao ERP?"
                icon={NotificationIcon.Info}
            />
            <ConfirmPaymentInvoiceModal
                show={showConfirmPaymentInvoiceModal}
                option={InvoiceStatus.InvoicePayed}
                invoice={invoice}
                onHide={() => {
                    setShowConfirmPaymentInvoiceModal(false);
                }}
            />
        </BaseLayout>
    );
}
