/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/array-type */
import { InputGroup, Spinner, Table, Form } from 'react-bootstrap';
import { StyledTh } from './styles';

interface IPropsSelectedPaymentMethod {
    paymentMethods: Array<any>;
    selectedPaymentMethod: any;
    isLoading?: boolean;
    term?: string;
    onSelected: (data: any) => void;
    handleFilterTerm?: (value: any) => void;
}

const SelectedPaymentMethod = (props: IPropsSelectedPaymentMethod) => {
    return (
        <Form.Row
            style={{
                marginRight: '-15px !important',
                overflowY: props.isLoading ? 'visible' : 'auto',
                maxHeight: '400px',
            }}
            className="mt-3 ml-2 mb-4"
        >
            <InputGroup className="mb-3">
                <InputGroup.Prepend>
                    <InputGroup.Text>
                        <i className="fas fa-search" />
                    </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                    type="text"
                    value={props.term}
                    onChange={(event) =>
                        props.handleFilterTerm(event.target.value)
                    }
                    placeholder="Pesquisar..."
                />
            </InputGroup>

            {props.isLoading ? (
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    <Spinner animation="border" />
                </div>
            ) : (
                <Table bordered hover size="sm" className="text-center">
                    <thead>
                        <tr>
                            <StyledTh />
                            <StyledTh>OPÇÃO</StyledTh>
                            <StyledTh>FORMA DE PAGAMENTO</StyledTh>
                        </tr>
                    </thead>
                    <tbody>
                        {props.paymentMethods?.map(
                            (item: any, index: number) => (
                                <tr key={index}>
                                    <td>
                                        <Form.Check
                                            checked={
                                                props.selectedPaymentMethod &&
                                                props.selectedPaymentMethod
                                                    .code === item.code
                                            }
                                            type="radio"
                                            name="selectedPaymentMethod"
                                            value={item.code}
                                            onChange={() => {
                                                props.onSelected(item);
                                            }}
                                        />
                                    </td>
                                    <td>{item.code}</td>
                                    <td>{item.description}</td>
                                </tr>
                            ),
                        )}
                    </tbody>
                </Table>
            )}
        </Form.Row>
    );
};

export default SelectedPaymentMethod;
