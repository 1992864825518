import { StatusCode } from 'status-code-enum';
import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';
import PaymentHttpService from '../services/http/payment-http';
import { SelectOption } from '../components/Select';
import BankAccount from '../shared/interfaces/bankAccount.interface';

type GetBankAccountsResponseData = AxiosResponse<BankAccount[]>;

interface useBankAccountsResponse {
    bankAccounts?: BankAccount[];
    bankAccountsOptions?: Array<SelectOption<number>>;
    isLoading: boolean;
}

export default function useBankAccounts(): useBankAccountsResponse {
    const getBankAccounts = async (): Promise<BankAccount[]> => {
        const { status, data: bankAccounts } =
            (await PaymentHttpService.listBankAccounts()) as GetBankAccountsResponseData;

        if (!bankAccounts || status !== StatusCode.SuccessOK) {
            throw new Error(
                'Não foi possivel buscar as contas de banco disponiveis!',
            );
        }

        return bankAccounts?.map((bankAccount: BankAccount) => ({
            id: bankAccount.id,
            bankNumber: bankAccount.bankNumber,
            bankName: bankAccount.bankName,
            branchCode: bankAccount.branchCode,
            bankAccount: bankAccount.bankAccount,
        }));
    };

    const { data: bankAccounts, isLoading } = useQuery({
        queryKey: ['bank-accounts'],
        queryFn: getBankAccounts,
        staleTime: 300000,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
    });

    const bankAccountsOptions: Array<SelectOption<number>> = bankAccounts?.map(
        ({
            id,
            bankName,
        }: BankAccount) => {
            return {
                value: id,
                label: `${id} - ${bankName}`,
            };
        },
    );

    return { bankAccounts, bankAccountsOptions, isLoading };
}
