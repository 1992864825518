/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prefer-const */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable dot-notation */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { Form, Modal, Nav, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import CustomerUser from './CustomerUser';
import CustomerAddress from './CustomerAddress';
import CustomerHttpService from '../../../services/http/customer-http';
import {
    customerUserRules,
    customerAddressRules,
} from '../../../validations/customer';
import getValidationsErrors from '../../../utils/getValidationsErrors';
import { getSelectedSeller } from '../../../utils/getSelectedSeller';
import { StyledNav } from '../../../styles/nav';
import {
    StyledConfirmButton,
    StyledOutlineButton,
} from '../../../styles/button';
import handleResponseError from '../../../utils/handleResponseError';
import IntegratorHttpService from '../../../services/http/integrator.http';

const New = (props: any) => {
    const newCustomer = () => ({
        id: '',
        documentType: 'J',
        document: '',
        name: '',
        fancyName: '',
        email: '',
        phone: '',
        ie: '',
        hasIe: true,
        phoneCode: '',
        addressCep: '',
        addressNumber: '',
        addressDescription: '',
        addressNeighborhood: '',
        addressComplement: '',
        erpState: '',
        erpCity: '',
        erpCityName: '',
    });
    const [tab, setTab] = useState('tab-1');
    const formRef: any = useRef<FormHandles>(null);
    const [errors, setErrors] = useState(newCustomer());
    const [documentType, setDocumentType] = useState('J');
    const [loading, setLoading] = useState(false);
    const [customer, setCustomer] = useState(newCustomer());

    useEffect(() => {
        if (props.show) {
            return;
        }

        setErrors(newCustomer());
        setLoading(false);

        setCustomer({
            ...newCustomer(),
            ...{ documentType: customer.documentType },
        });

        setTab('tab-1');

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show]);

    const handleChanges = (event: any) => {
        const changes: any = {};
        const newErrors: any = {};
        let events = event;

        if (!Array.isArray(event)) {
            events = [event];
        }

        events.forEach((item: any) => {
            if (item.target.name === 'erpState') {
                changes['erpState'] =
                    customer.erpState || item.target.value.code;
                changes['erpCity'] =
                    customer.erpCityName && customer.erpCity
                        ? {
                              value: customer.erpCity,
                              label: customer.erpCityName,
                          }
                        : '';
            }

            if (item.target.name === 'hasIe' && item.target.value === false) {
                changes['ie'] = 'ISENTO';
            }

            if (item.target.name === 'hasIe' && item.target.value === true) {
                changes['ie'] = '';
            }

            if (item.target.name === 'hasIe') {
                changes['hasIe'] = !customer.hasIe;

                return;
            }

            changes[item.target.name] = item.target.value;
            newErrors[item.target.name] = undefined;
        });
        console.log(changes);
        setCustomer({ ...customer, ...changes });
        setErrors({ ...errors, ...newErrors });
    };

    const prepareData = async (data: any) => {
        const seller = getSelectedSeller();
        const integrator = seller
            ? (await IntegratorHttpService.readOne(seller.id)).data
            : undefined;
        const customer = {
            id: data.id ? data.id : undefined,
            name: data.name
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            phone: data.phone.toString().replace(/\D/g, '').substring(2),
            ie: data.hasIe === true ? data.ie : 'ISENTO',
            erpCity:
                typeof data.erpCity === 'object'
                    ? data.erpCity.value
                    : data.erpCity,
            erpCityName:
                typeof data.erpCity === 'object'
                    ? data.erpCity.label
                    : data.erpCityName,
            erpState:
                typeof data.erpState === 'object'
                    ? data.erpState.value
                    : data.erpState,
            addressCep: data.addressCep.toString().replace(/\D/g, ''),
            addressNumber: data.addressNumber
                .toString()
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressDescription: data.addressDescription
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressNeighborhood: data.addressNeighborhood
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            addressComplement: data.addressComplement
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            fancyName:
                data.documentType === 'J'
                    ? data.fancyName
                          .normalize('NFD')
                          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
                    : data.name
                          .normalize('NFD')
                          .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            documentType: data.documentType,
            document: data.document
                .toString()
                .replace(/\D/g, '')
                .normalize('NFD')
                .replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, ''),
            email: data.email,
            phoneCode: `0${data.phone.match(/\d+/)[0]}`,
            sellerCode: integrator ? integrator?.erpCode : '',
        };

        return customer;
    };

    const save = async () => {
        try {
            const schema = Yup.object().shape(customerUserRules);

            await schema.validate(customer, {
                abortEarly: false,
                context: customer,
            });
        } catch (error) {
            setErrors(getValidationsErrors(error) as any);

            return setTab('tab-1');
        }

        try {
            const schema = Yup.object().shape(customerAddressRules);

            await schema.validate(customer, { abortEarly: false });
        } catch (error) {
            setErrors(getValidationsErrors(error) as any);

            return setTab('tab-2');
        }

        const data = await prepareData(customer);

        try {
            await CustomerHttpService.create(data);
            toast.success('Cliente cadastrado com sucesso');
            props.onHide(true);
        } catch (error) {
            handleResponseError(error, 'Erro ao salvar cadastro do cliente');
        }
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoading(true);

        await save();

        setLoading(false);
    };

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.id ? customer.name : 'Adicionar um novo cliente'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form ref={formRef} onSubmit={handleSubmit}>
                    <Form.Group>
                        <Form.Check
                            disabled={props.id}
                            checked={customer.documentType === 'J'}
                            type={'radio'}
                            name="documentType"
                            value="J"
                            onClick={() => {
                                setDocumentType('J');
                            }}
                            onChange={handleChanges}
                            label={'Meu cliente é pessoa juridica'}
                        />
                        <Form.Check
                            disabled={props.id}
                            checked={customer.documentType === 'F'}
                            type={'radio'}
                            name="documentType"
                            value="F"
                            onClick={() => {
                                setDocumentType('F');
                            }}
                            onChange={handleChanges}
                            label={'Meu cliente é pessoa fisica'}
                        />
                        <Form.Row
                            style={{ marginRight: '-15px !important' }}
                            className={'mt-3 ml-2 mb-4'}
                        >
                            <StyledNav
                                fill
                                variant="tabs"
                                activeKey={tab}
                                onSelect={(selectedKey: any) =>
                                    setTab(selectedKey)
                                }
                            >
                                <Nav.Item>
                                    <Nav.Link eventKey="tab-1">
                                        Dados Gerais
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab-2">
                                        Endereço
                                    </Nav.Link>
                                </Nav.Item>
                            </StyledNav>
                        </Form.Row>
                    </Form.Group>
                    {tab === 'tab-1' ? (
                        <CustomerUser
                            errors={errors}
                            customer={customer}
                            documentType={documentType}
                            handleChanges={handleChanges}
                        />
                    ) : (
                        <CustomerAddress
                            errors={errors}
                            customer={customer}
                            handleChanges={handleChanges}
                        />
                    )}
                </Form>
            </Modal.Body>

            <Modal.Footer>
                <StyledOutlineButton
                    variant={'outline-primary'}
                    onClick={() => {
                        props.onHide(false);
                    }}
                    disabled={false}
                >
                    Cancelar
                </StyledOutlineButton>
                <StyledConfirmButton onClick={handleSubmit} disabled={loading}>
                    {loading ? <Spinner animation={'border'} /> : 'Salvar'}
                </StyledConfirmButton>
            </Modal.Footer>
        </Modal>
    );
};

export { New };
