import { Controller, UseFormMethods } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { InvoiceValidationFields } from '../InvoiceValidationForm';

interface HighlightShippingNfToggleProps {
    form: UseFormMethods<InvoiceValidationFields>;
}

export default function HighlightShippingNfToggle({
    form,
}: HighlightShippingNfToggleProps) {
    const { highlightShippingNf } = form.watch();
    return (
        <>
            <Form.Group controlId="formBasicHighlightShippingNf">
                <Form.Label style={{ margin: 0 }}>
                    Destacar Frete na Nota
                </Form.Label>
                <Controller
                    control={form.control}
                    name="highlightShippingNf"
                    render={({ value }) => (
                        <Form.Check
                            type="switch"
                            className="float-left"
                            onChange={() => {
                                form.setValue(
                                    'highlightShippingNf',
                                    !highlightShippingNf,
                                );
                            }}
                            checked={value}
                        />
                    )}
                />
            </Form.Group>
        </>
    );
}
