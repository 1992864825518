/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-curly-brace-presence */
import React, { useEffect, useState } from 'react';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import Highlighter from 'react-highlight-words';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import {
    StyledDesc,
    StyledPrice,
    StyledId,
} from '../../../pages/OnlineStore/styles';
import { formatToQueryParam } from '../../../utils/strings';
import { toFixed } from '../../../utils/toFixed';
import { ReactComponent as ReactDangerIcon } from '../../../assets/icons/danger.svg';
import GeneratorsImageCard from './image';
import LibraryHttpService from '../../../services/http/library-http';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { replaceItemAtIndex } from '../../../utils/replace-array';
import CartProductNumericInput from '../InvoiceProductNumericInput/components/cart-product-numeric-input';

interface Props {
    data: any;
}

const GeneratorsCard: React.FC<Props> = ({ data }) => {
    const {
        setShowDownloadTechnicalDocumentModal,
        cart,
        term,
        productType,
        setItems,
        items,
        setLoadingCart,
    } = useOnlineStore();

    const [storageItens, setStorageItens] = useState(data);

    async function downloadFT(link: string) {
        const linkArray = link.split('/');
        const folder = linkArray[1];
        const file = linkArray[2];
        await LibraryHttpService.downloadItem(folder, file);
    }

    const createBaseCart = async () => {
        const baseCart = await InvoiceHttpService.createBaseCart();
        setItems(
            baseCart.data.invoiceItems?.map((item: any) => {
                return {
                    B1_COD: item.productErpCode,
                    quantity: item.quantity,
                };
            }) ?? [],
        );
    };

    useEffect(() => {
        if (
            (storageItens &&
                data &&
                storageItens.length &&
                data.length &&
                (storageItens.length !== data.length ||
                    storageItens[0].B1_COD !== data[0].B1_COD)) ||
            !data ||
            !data.length ||
            !storageItens ||
            !storageItens.length
        ) {
            setStorageItens(data);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        createBaseCart();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (items && items.length) {
            setStorageItens(() => {
                return [
                    ...data.map((product: any) => {
                        const cartItem = ((items as any[]) ?? []).find(
                            (item) => item.B1_COD === product.B1_COD,
                        );
                        if (cartItem) {
                            // eslint-disable-next-line no-param-reassign
                            product.quantity = cartItem.quantity;
                        }
                        return { ...product };
                    }),
                ];
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [items, data]);

    return (
        <Row className="mx-4 justify-content-md-center">
            {storageItens?.map((item: any, index: any) => (
                <Col key={index} sm={12} lg={4} md={6} xl="auto">
                    <div>
                        <Row className={'w-75'}>
                            <GeneratorsImageCard
                                data={item}
                                onClick={() => {
                                    const files = item.FT as Array<{
                                        nameFile: string;
                                        link: string;
                                    }>;
                                    if (files.length === 1) {
                                        downloadFT(files[0].link);
                                        return;
                                    }

                                    setShowDownloadTechnicalDocumentModal(
                                        files,
                                    );
                                }}
                            />
                        </Row>
                        <Row>
                            <StyledId>
                                Cod.
                                <Highlighter
                                    autoEscape
                                    highlightClassName="highlight-term"
                                    searchWords={[formatToQueryParam(term)]}
                                    textToHighlight={item.B1_COD}
                                />
                            </StyledId>
                        </Row>
                        <Row className={'w-75'}>
                            <StyledDesc>
                                <Highlighter
                                    autoEscape
                                    highlightClassName="highlight-term"
                                    searchWords={[formatToQueryParam(term)]}
                                    textToHighlight={item.B1_DESC}
                                />
                            </StyledDesc>
                        </Row>

                        <Row>
                            <StyledPrice>
                                {toFixed(item.VLR_TOT, 2).toLocaleString(
                                    'pt-br',
                                    {
                                        style: 'currency',
                                        currency: 'BRL',
                                    },
                                )}
                            </StyledPrice>
                        </Row>
                        <Row className="pb-2">
                            <Col sm={4} className="p-0">
                                <CartProductNumericInput
                                    localItem={item}
                                    quantityValue={item.quantity}
                                    allItens={cart?.invoiceItems ?? []}
                                    productType={productType}
                                    setLoading={(loadingData) => {
                                        if (loadingData) {
                                            setLoadingCart(loadingData);
                                        }
                                    }}
                                    cartId={cart?.id}
                                    onChange={(dataItem) => {
                                        setStorageItens((valueData: any[]) => {
                                            const itemToUpdateIndex =
                                                valueData.findIndex(
                                                    (value) =>
                                                        value.B1_COD ===
                                                        dataItem.B1_COD,
                                                );
                                            if (itemToUpdateIndex !== -1) {
                                                return replaceItemAtIndex(
                                                    valueData,
                                                    itemToUpdateIndex,
                                                    {
                                                        ...valueData[
                                                            itemToUpdateIndex
                                                        ],
                                                        quantity:
                                                            dataItem.quantity,
                                                    },
                                                );
                                            }
                                            return valueData;
                                        });
                                    }}
                                />
                            </Col>
                            <Col>
                                {item.remainingQuantity <= 0 && (
                                    <OverlayTrigger
                                        placement="top"
                                        overlay={
                                            <Tooltip id={'tooltip'}>
                                                Produto fora de estoque!
                                            </Tooltip>
                                        }
                                    >
                                        <ReactDangerIcon
                                            fill="#F00"
                                            width="30"
                                            height="30"
                                        />
                                    </OverlayTrigger>
                                )}
                            </Col>
                        </Row>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

GeneratorsCard.propTypes = {
    data: PropTypes.array,
};

export default GeneratorsCard;
