import { Form, Modal, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { invoicesListRoute } from '../../../../routes/config';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import getSanitizedDiscount from '../../../../utils/getSanitizedDiscount';
import isFriendlyHttpError from '../../../../utils/isFriendlyHttpError';
import DeliveryDateInput from '../DeliveryDateInput';
import DiscountInput from '../DiscountInput';
import FeedbackTextArea from '../FeedbackTextArea';
import FutureSaleToggle from '../FutureSaleToggle';
import ValidateButton from '../ValidateButton';
import history from '../../../../services/history';
import InvoiceSummary from '../../InvoiceSummary';
import getSubTotalInvoice from '../../../../utils/getSubTotalInvoice';
import getMaxAvailabilityDate from '../../../../utils/getMaxAvailabilityDate';
import parseDate from '../../../../utils/parseDate';
import handleResponseError from '../../../../utils/handleResponseError';
import { PaymentMethod } from '../../../../constants/onlineStore';
import HighlightShippingNfToggle from '../HighlightShippingNf';

interface InvoiceValidationFormProps {
    invoice: any;
    onSubmitError: () => void;
    minDeliveryDate: Date;
    dateWithQuantity: Array<{ date: Date; quantity: number }>;
}

export interface InvoiceValidationFields {
    discount: string;
    freightDiscount: string;
    deliveryDate: Date | null;
    isFutureSale: boolean;
    isTriangulation: boolean;
    feedback: string;
    highlightShippingNf: boolean;
}

export default function InvoiceValidationForm({
    invoice,
    onSubmitError,
    minDeliveryDate,
    dateWithQuantity,
}: InvoiceValidationFormProps) {
    const willPickup = invoice.addressType === 'pickup';
    const defaultDeliveryDate = willPickup
        ? parseDate(getMaxAvailabilityDate(invoice))
        : null;

    const form = useForm<InvoiceValidationFields>({
        shouldUnregister: false,
        defaultValues: {
            discount: '0',
            freightDiscount: '0',
            deliveryDate: defaultDeliveryDate,
            isFutureSale: false,
            isTriangulation:
                invoice.seller.canDoTriangulation &&
                invoice.customerType === 'INTEGRATOR',
            feedback: '',
            highlightShippingNf:
                invoice.paymentMethod !== PaymentMethod.Financing,
        },
    });

    const formData = form.watch();

    const validateMutation = useMutation({
        mutationKey: ['validate-invoice', invoice.id],
        mutationFn: async () => {
            try {
                await InvoiceHttpService.calcFreightFretefyValue(invoice.id);
            } catch (error) {
                handleResponseError(
                    error,
                    'Erro ao calcular valor de frete na Fretefy',
                );
            }

            await InvoiceHttpService.validate(invoice.id, {
                ...formData,
                discount: getSanitizedDiscount(formData.discount),
                deliveryDate: formData.deliveryDate.toISOString(),
            });
        },
        onSuccess: () => {
            toast.success('Pedido validado com sucesso');

            history.push(invoicesListRoute.path);
        },
        onError: (error: any) => {
            onSubmitError();

            handleResponseError(error, 'Ocorreu um erro ao validar o pedido');
        },
    });

    const discountMutation = useMutation({
        mutationKey: ['invoice-discount', invoice.id],
        mutationFn: () =>
            InvoiceHttpService.simulateUpdateDiscount(invoice.id, {
                discountValue: getSanitizedDiscount(formData.discount),
            }),
        onSuccess: ({ data }: any) => {
            Object.assign(invoice, {
                discountValue: data.discountValue,
                interestValue: data.interestValue,
                percentage: data.percentage,
                freightValue: data.freightValue,
                totalInvoice: data.totalInvoice,
                marginLiquid: data.marginLiquid,
            });
        },
        onError: (error: any) => {
            onSubmitError();

            if (isFriendlyHttpError(error)) {
                toast.error(error.message as string);

                return;
            }

            toast.error('Ocorreu um erro ao aplicar desconto no pedido');
        },
    });

    useEffect(() => {
        discountMutation.mutateAsync();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData.discount]);

    const onSubmit = form.handleSubmit(() => validateMutation.mutateAsync());

    return (
        <Form onSubmit={onSubmit}>
            <Modal.Header closeButton>
                <Modal.Title>Validação do pedido</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col md={6}>
                        <DiscountInput
                            form={form}
                            disabled={validateMutation.isLoading}
                            maxValue={getSubTotalInvoice(invoice)}
                        />
                        <DeliveryDateInput
                            form={form}
                            disabled={validateMutation.isLoading || willPickup}
                            minDeliveryDate={minDeliveryDate}
                            datesWithQuantity={dateWithQuantity}
                        />
                        <FutureSaleToggle
                            form={form}
                            disabled={validateMutation.isLoading}
                        />
                        <HighlightShippingNfToggle form={form} />
                        {/* <TriangulationToggle
                            form={form}
                            disabled={validateMutation.isLoading}
                            invoice={invoice}
                        /> */}
                    </Col>
                    <Col md={6}>
                        <InvoiceSummary
                            invoice={invoice}
                            showInstallmentRow={false}
                            loading={discountMutation.isLoading}
                        />
                    </Col>
                    <Col md={12}>
                        <FeedbackTextArea
                            form={form}
                            disabled={validateMutation.isLoading}
                        />
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <ValidateButton isSubmitting={validateMutation.isLoading} />
            </Modal.Footer>
        </Form>
    );
}
