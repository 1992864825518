import {
    useState,
    createContext,
    ReactNode,
    Dispatch,
    SetStateAction,
} from 'react';
import { UseFormMethods, useForm } from 'react-hook-form';
import { SelectOption } from '../components/Select';
import useDelayedState from '../hooks/useDelayedState';

interface InvoiceFilterFields {
    filterOrder: SelectOption<string> | null;
    filterOrderType: SelectOption<string> | null;
    filterStatus: Array<SelectOption<string>> | null;
    filterSeller: Array<SelectOption<number>> | null;
    filterRegion: Array<SelectOption<string>> | null;
    filterModules: Array<SelectOption<string>> | null;
    filterInverters: Array<SelectOption<string>> | null;
    filterPaymentMethod: Array<SelectOption<string>> | null;
    filterFutureSale: SelectOption<number> | null;
    filterTriangulationStatus: Array<SelectOption<string>> | null;
    filterAttachment: SelectOption<boolean> | null;
}

interface InvoicesContextValue {
    selectedInvoice: any;
    setSelectedInvoice: Dispatch<SetStateAction<any>>;

    showFilterSection: boolean;
    setShowFilterSection: Dispatch<SetStateAction<boolean>>;

    showPaymentMethodModal: boolean;
    setShowPaymentMethodModal: Dispatch<SetStateAction<boolean>>;

    showFeedbackInvoiceModal: boolean;
    setShowFeedbackInvoiceModal: Dispatch<SetStateAction<boolean>>;

    showPreReturnModal: boolean;
    setShowPreReturnModal: Dispatch<SetStateAction<boolean>>;

    showInvoiceTriangulationNfDisplayModal: boolean;
    setShowInvoiceTriangulationNfDisplayModal: Dispatch<
        SetStateAction<boolean>
    >;

    showInvoiceSimpleShipmentNfDisplayModal: boolean;
    setShowInvoiceSimpleShipmentNfDisplayModal: Dispatch<
        SetStateAction<boolean>
    >;

    showInvoiceCancelmentModal: boolean;
    setShowInvoiceCancelmentModal: Dispatch<SetStateAction<boolean>>;

    showReleasePaymentMethodInvoiceModal: boolean;
    setShowReleasePaymentMethodInvoiceModal: Dispatch<SetStateAction<boolean>>;

    showInvoiceApprovalModal: boolean;
    setShowInvoiceApprovalModal: Dispatch<SetStateAction<boolean>>;

    showListAttachmentsModal: boolean;
    setShowListAttachmentsModal: Dispatch<SetStateAction<boolean>>;

    showReturnConfirmation: boolean;
    setShowReturnConfirmation: Dispatch<SetStateAction<boolean>>;

    showReproveValidatedConfirmation: boolean;
    setShowReproveValidatedConfirmation: Dispatch<SetStateAction<boolean>>;

    showReproveConfirmation: boolean;
    setShowReproveConfirmation: Dispatch<SetStateAction<boolean>>;

    showRemakeConfirmation: boolean;
    setShowRemakeConfirmation: Dispatch<SetStateAction<boolean>>;

    showRemoveConfirmation: boolean;
    setShowRemoveConfirmation: Dispatch<SetStateAction<boolean>>;

    showMakeConfirmation: boolean;
    setShowMakeConfirmation: Dispatch<SetStateAction<boolean>>;

    showSendFretefyConfirmation: boolean;
    setShowSendFretefyConfirmation: Dispatch<SetStateAction<boolean>>;

    filterTerm: string;
    setFilterTerm: Dispatch<SetStateAction<string>>;
    filterTermWithoutDelay: string;

    filterForm: UseFormMethods<InvoiceFilterFields>;
}

export const InvoicesContext = createContext<InvoicesContextValue>(
    {} as InvoicesContextValue,
);

interface InvoicesProviderProps {
    children: ReactNode;
}

export default function InvoicesProvider({ children }: InvoicesProviderProps) {
    const [selectedInvoice, setSelectedInvoice] = useState<any>(null);

    const [showFilterSection, setShowFilterSection] = useState<boolean>(false);

    const [showPaymentMethodModal, setShowPaymentMethodModal] =
        useState<boolean>(false);

    const [showFeedbackInvoiceModal, setShowFeedbackInvoiceModal] =
        useState<boolean>(false);

    const [showPreReturnModal, setShowPreReturnModal] =
        useState<boolean>(false);

    const [
        showInvoiceTriangulationNfDisplayModal,
        setShowInvoiceTriangulationNfDisplayModal,
    ] = useState<boolean>(false);

    const [
        showInvoiceSimpleShipmentNfDisplayModal,
        setShowInvoiceSimpleShipmentNfDisplayModal,
    ] = useState<boolean>(false);

    const [showInvoiceCancelmentModal, setShowInvoiceCancelmentModal] =
        useState<boolean>(false);

    const [
        showReleasePaymentMethodInvoiceModal,
        setShowReleasePaymentMethodInvoiceModal,
    ] = useState<boolean>(false);

    const [showInvoiceApprovalModal, setShowInvoiceApprovalModal] =
        useState<boolean>(false);

    const [showListAttachmentsModal, setShowListAttachmentsModal] =
        useState<boolean>(false);

    const [showRemakeConfirmation, setShowRemakeConfirmation] =
        useState<boolean>(false);

    const [showReproveConfirmation, setShowReproveConfirmation] =
        useState<boolean>(false);

    const [
        showReproveValidatedConfirmation,
        setShowReproveValidatedConfirmation,
    ] = useState<boolean>(false);

    const [showReturnConfirmation, setShowReturnConfirmation] =
        useState<boolean>(false);

    const [showRemoveConfirmation, setShowRemoveConfirmation] =
        useState<boolean>(false);

    const [showMakeConfirmation, setShowMakeConfirmation] =
        useState<boolean>(false);

    const [showSendFretefyConfirmation, setShowSendFretefyConfirmation] =
        useState<boolean>(false);

    const [filterTerm, setFilterTerm, filterTermWithoutDelay] =
        useDelayedState('');

    const filterForm = useForm<InvoiceFilterFields>({
        shouldUnregister: false,
        defaultValues: {
            filterOrder: null,
            filterOrderType: null,
            filterStatus: null,
            filterSeller: null,
            filterRegion: null,
            filterModules: null,
            filterInverters: null,
            filterPaymentMethod: null,
            filterFutureSale: null,
            filterTriangulationStatus: null,
            filterAttachment: null,
        },
    });

    const value: InvoicesContextValue = {
        selectedInvoice,
        setSelectedInvoice,
        showFilterSection,
        setShowFilterSection,
        showPaymentMethodModal,
        setShowPaymentMethodModal,
        showFeedbackInvoiceModal,
        setShowFeedbackInvoiceModal,
        showPreReturnModal,
        setShowPreReturnModal,
        showInvoiceTriangulationNfDisplayModal,
        setShowInvoiceTriangulationNfDisplayModal,
        showInvoiceSimpleShipmentNfDisplayModal,
        setShowInvoiceSimpleShipmentNfDisplayModal,
        showInvoiceCancelmentModal,
        setShowInvoiceCancelmentModal,
        showReleasePaymentMethodInvoiceModal,
        setShowReleasePaymentMethodInvoiceModal,
        showInvoiceApprovalModal,
        setShowInvoiceApprovalModal,
        showListAttachmentsModal,
        setShowListAttachmentsModal,
        showRemakeConfirmation,
        setShowRemakeConfirmation,
        showReproveConfirmation,
        setShowReproveConfirmation,
        showReproveValidatedConfirmation,
        setShowReproveValidatedConfirmation,
        showReturnConfirmation,
        setShowReturnConfirmation,
        showRemoveConfirmation,
        setShowRemoveConfirmation,
        showMakeConfirmation,
        setShowMakeConfirmation,
        showSendFretefyConfirmation,
        setShowSendFretefyConfirmation,
        filterTerm,
        setFilterTerm,
        filterTermWithoutDelay,
        filterForm,
    };

    return (
        <InvoicesContext.Provider value={value}>
            {children}
        </InvoicesContext.Provider>
    );
}
