import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from 'react-query';
import { useCallback, useState } from 'react';
import InvoiceHttpService from '../../../services/http/invoice-http';
import isFriendlyHttpError from '../../../utils/isFriendlyHttpError';
import LoadingModalBody from '../../LoadingModalBody';
import InvoiceValidationForm from './InvoiceValidationForm';
import handleResponseError from '../../../utils/handleResponseError';

interface InvoiceValidationModalProps {
    show: boolean;
    onHide: () => void;
    invoiceId: number;
}

export default function InvoiceValidationModal({
    show,
    onHide,
    invoiceId,
}: InvoiceValidationModalProps) {
    const [minDeliveryDate, setMinDeliveryDate] = useState<Date>(new Date());
    const [datesWithQuantity, setDatesWithQuantity] = useState<
        Array<{ date: Date; quantity: number }>
    >([]);

    const commissionMutation = useMutation({
        mutationFn: () => InvoiceHttpService.updateCommission(invoiceId),
        onError: () => {
            onHide();
            toast.error(
                'Ocorreu um erro ao atualizar o valor líquido da comissão',
            );
        },
        onSuccess: async (data) => {
            if (data.data?.updated) {
                toast.warn('Valor líquido da comissão atualizado');
            }
        },
    });

    const waitingCommissionUpdate =
        commissionMutation.isLoading || commissionMutation.isIdle;

    const recalculateMutation = useMutation({
        mutationFn: () => InvoiceHttpService.recalculate(invoiceId.toString()),
        onError: (error: any) => {
            onHide();

            if (isFriendlyHttpError(error)) {
                toast.error(error.message as string);

                return;
            }

            toast.error(
                'Erro ao atualizar disponibilidade dos itens do pedido',
            );
        },
        onSuccess: async ({ data }) => {
            toast.info(
                data?.updated
                    ? 'A disponibilidade dos itens foi atualizada'
                    : 'Não ocorreram atualizações de disponibilidade',
            );

            commissionMutation.mutate();
        },
    });

    const waitingRecalculate =
        recalculateMutation.isLoading || recalculateMutation.isIdle;

    const canValidateQuery = useQuery({
        queryKey: ['can-validate', invoiceId],
        queryFn: async () => {
            const response = await InvoiceHttpService.canValidate(invoiceId);
            return response.data;
        },
        onError: (error: any) => {
            onHide();
            return handleResponseError(
                error,
                'Erro ao verificar se o pedido pode ser validado',
            );
        },
        onSuccess: async (data) => {
            console.log(data);
            setMinDeliveryDate(new Date(data?.minDeliveryDate));
            setDatesWithQuantity(
                data?.deliveryDateSeparationCapacity.map((value: any) => {
                    return {
                        date: new Date(value.date),
                        quantity: value.quantity,
                    };
                }),
            );
        },
        enabled: !waitingRecalculate && !waitingCommissionUpdate,
        refetchOnWindowFocus: false,
        refetchOnMount: 'always',
        refetchOnReconnect: false,
        retry: false,
    });

    // const invoiceQuery = useQuery({
    //     queryKey: ['invoice', invoiceId],
    //     queryFn: async () => {
    //         const response = await InvoiceHttpService.show(
    //             invoiceId.toString(),
    //         );

    //         return response.data;
    //     },
    //     onError: (error: any) => {
    //         onHide();

    //         if (isFriendlyHttpError(error)) {
    //             toast.error(error.message as string);

    //             return;
    //         }

    //         toast.error('Ocorreu um erro ao buscar os dados do pedido');
    //     },
    //     refetchOnWindowFocus: false,
    //     refetchOnMount: 'always',
    //     refetchOnReconnect: false,
    //     retry: false,
    // });

    const showProgressBar =
        // invoiceQuery.isFetching ||
        waitingRecalculate ||
        waitingCommissionUpdate ||
        canValidateQuery.isFetching;

    const getLoadingMessage = useCallback(() => {
        if (waitingRecalculate) {
            return 'Atualizando a disponibilidade dos itens do pedido...';
        }
        if (waitingCommissionUpdate) {
            return 'Atualizando valor líquido da comissão...';
        }

        return 'Verificando se o pedido pode ser validado...';
    }, [waitingCommissionUpdate, waitingRecalculate]);

    const loadingMessage = getLoadingMessage();

    const performUpdates = useCallback(() => {
        recalculateMutation.mutate();
    }, [recalculateMutation]);

    return (
        <Modal
            aria-labelledby="invoice-validation-modal"
            show={show}
            onHide={onHide}
            size="lg"
            centered
            backdrop={!showProgressBar || 'static'}
            onShow={performUpdates}
        >
            {showProgressBar ? (
                <LoadingModalBody message={loadingMessage} />
            ) : (
                <InvoiceValidationForm
                    invoice={canValidateQuery?.data?.invoice}
                    onSubmitError={onHide}
                    minDeliveryDate={minDeliveryDate}
                    dateWithQuantity={datesWithQuantity}
                />
            )}
        </Modal>
    );
}
