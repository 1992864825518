/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable prefer-const */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable no-param-reassign */
/* eslint-disable react/self-closing-comp */
/* eslint-disable indent */
import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import InvoiceHttpService from '../../../services/http/invoice-http';
import { useAuth } from '../../../contexts/authContext';
import userHasRoles from '../../../utils/userHasRoles';
import { Role } from '../../../constants/role';
import { formatCurrency } from '../../../utils/strings';
import { InvoiceStatus } from '../../../constants/invoiceStatus';

interface Props {
    invoice: any;
    disabled: any;
}

const AdditionalData: React.FC<Props> = ({ invoice, disabled }) => {
    let { setReloadCart } = useOnlineStore();
    const [additionalData, setAdditionalData] = useState(
        invoice.additionalData,
    );
    const { user } = useAuth();
    const isAdmin = userHasRoles(user, [Role.Administrator]);

    return (
        <>
            <Form>
                <Form.Group className={'pt-4'} controlId="formBasicCep">
                    <Form.Label>Dados adicionais da Nota Fiscal</Form.Label>
                    <Form.Control
                        disabled={disabled}
                        onBlur={async () => {
                            invoice.additionalData = additionalData;
                            setReloadCart((reload: boolean) => !reload);

                            await InvoiceHttpService.update(invoice).catch(() =>
                                toast.error('Erro ao salvar!'),
                            );
                        }}
                        as="textarea"
                        name="power"
                        onChange={(event: any) => {
                            setAdditionalData(
                                event.target.value.replace(/[<>"'&]/g, ''),
                            );
                        }}
                        value={additionalData ? additionalData : ''}
                        rows={3}
                    ></Form.Control>

                    {isAdmin && invoice?.fretefyFreightValue && (
                        <>
                            <Form.Label>Valor de frete - Fretefy</Form.Label>
                            <Form.Control
                                disabled
                                value={`R$ ${formatCurrency(
                                    invoice?.fretefyFreightValue ?? '0',
                                )}`}
                            ></Form.Control>
                        </>
                    )}
                    {isAdmin && invoice?.totalCubage && (
                        <>
                            <Form.Label>Cubagem total do pedido</Form.Label>
                            <Form.Control
                                disabled
                                value={invoice?.totalCubage ?? ''}
                            ></Form.Control>
                        </>
                    )}
                    {![
                        InvoiceStatus.Cart,
                        InvoiceStatus.Budget,
                        InvoiceStatus.BudgetExpired,
                        InvoiceStatus.Invoice,
                    ].includes(invoice.status) && (
                        <>
                            <Form.Label style={{ margin: '1px 0px' }}>
                                Destacar Frete na Nota Fiscal
                            </Form.Label>
                            <Form.Control
                                disabled
                                value={
                                    invoice?.highlightShippingNf ? 'Sim' : 'Não'
                                }
                            ></Form.Control>
                        </>
                    )}
                </Form.Group>
            </Form>
        </>
    );
};

AdditionalData.propTypes = {
    invoice: PropTypes.object,
    disabled: PropTypes.bool,
};

export default AdditionalData;
