/* eslint-disable consistent-return */
import { useEffect, useState } from 'react';
import {
    Button,
    Form,
    Modal,
    ModalFooter,
    Nav,
    Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import PaymentHttpService from '../../../../services/http/payment-http';
import InvoiceHttpService from '../../../../services/http/invoice-http';
import { StyledNav } from '../../../../styles/nav';
import SelectedPaymentMethod from './SelectedPaymentMethod';
import DetailPaymentMethod from './DetailPaymentMethod';
import handleResponseError from '../../../../utils/handleResponseError';
import { formatCurrency } from '../../../../utils/strings';

interface ReleasePaymentMethodModalProps {
    show: boolean;
    onHide: () => void;
    invoice: any;
}

export default function ReleasePaymentMethodModal({
    show,
    onHide,
    invoice,
}: ReleasePaymentMethodModalProps) {
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [selectedPaymentMethod, setSelectedPaymentMethod] = useState({
        code: '',
        description: '',
        quantityInstallment: 0,
        rangeInstallment: 0,
        installments: [],
    });
    const [term, setTerm] = useState<string>('');
    const [tab, setTab] = useState('tab-1');
    const [isLoading, setLoading] = useState<boolean>(false);
    const [isButtonLoading, setButtonLoading] = useState<boolean>(false);

    const clearReactStates = () => {
        setPaymentMethods([]);
        setSelectedPaymentMethod({
            code: '',
            description: '',
            quantityInstallment: 0,
            rangeInstallment: 0,
            installments: [],
        });
        setTerm('');
    };

    const loadPaymentMethods = async () => {
        setLoading(true);

        try {
            const { data } =
                await PaymentHttpService.readManyOthersPaymentMethods({
                    all: true,
                    term,
                });

            setPaymentMethods(data.data);
        } catch (error) {
            toast.error(
                'Houve um erro ao tentar listar os métodos de pagamento, tente novamente mais tarde.',
            );
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        if (
            Object.keys(selectedPaymentMethod).length === 0 &&
            selectedPaymentMethod.constructor === Object
        ) {
            return toast.info('Selecione uma forma de pagamento');
        }

        if (!selectedPaymentMethod?.installments?.length) {
            return toast.error('Forma de pagamento inválida.');
        }

        const selectedPaymentMethodDto = {
            ...selectedPaymentMethod,
            installments: selectedPaymentMethod.installments.map(
                (installment) => {
                    return {
                        ...installment,
                        value: parseFloat(
                            installment.value?.replace(',', '.') || '0',
                        ),
                    };
                },
            ),
        };

        const installmentTotalValue =
            selectedPaymentMethodDto.installments.reduce(
                (sum, installment) => sum + installment.value,
                0,
            );

        if (
            selectedPaymentMethodDto.installments.length === 0 ||
            installmentTotalValue.toFixed(2) !== invoice.totalInvoice.toFixed(2)
        ) {
            return toast.warn(
                `Soma do valor das parcelas (${formatCurrency(
                    installmentTotalValue,
                )}) diferente do valor total do pedido.`,
            );
        }

        setButtonLoading(true);

        try {
            const response = await InvoiceHttpService.selectOthersPaymentMethod(
                invoice.id,
                selectedPaymentMethodDto,
            );
            if (response.status === 200) {
                toast.success('Forma de pagamento selecionada com sucesso!');
            }
            onHide();
        } catch (error) {
            handleResponseError(
                error,
                'Houve um erro ao selecionar este método de pagamento, tente novamente mais tarde.',
            );
        } finally {
            setButtonLoading(false);
        }
    };

    useEffect(() => {
        if (show) {
            loadPaymentMethods();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [term]);

    useEffect(() => {
        if (show) {
            clearReactStates();
            loadPaymentMethods();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show]);

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onExit={() => false}
        >
            <Modal.Header closeButton>
                <Modal.Title
                    className="ml-3 mr-3"
                    id="contained-modal-title-vcenter"
                >
                    Selecione uma forma de pagamento
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="ml-3 mr-3">
                <Form>
                    <Form.Group>
                        <Form.Row
                            style={{ marginRight: '-15px !important' }}
                            className="mt-3 ml-2 mb-4"
                        >
                            <StyledNav fill variant="tabs" activeKey={tab}>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab-1">
                                        Seleção
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="tab-2">
                                        Detalhes
                                    </Nav.Link>
                                </Nav.Item>
                            </StyledNav>
                        </Form.Row>
                    </Form.Group>
                    <Form.Group>
                        {tab === 'tab-1' ? (
                            <SelectedPaymentMethod
                                paymentMethods={paymentMethods}
                                term={term}
                                selectedPaymentMethod={selectedPaymentMethod}
                                onSelected={(data) =>
                                    setSelectedPaymentMethod(data)
                                }
                                handleFilterTerm={(value) => setTerm(value)}
                                isLoading={isLoading}
                            />
                        ) : (
                            <DetailPaymentMethod
                                totalValue={parseFloat(
                                    invoice.totalInvoice.toFixed(2),
                                )}
                                selectedPaymentMethod={selectedPaymentMethod}
                                onChange={(data) => {
                                    setSelectedPaymentMethod(data);
                                }}
                            />
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <ModalFooter>
                <Button
                    variant="light"
                    style={{ backgroundColor: '#eee' }}
                    onClick={() => setTab('tab-1')}
                    disabled={tab === 'tab-1'}
                >
                    Voltar
                </Button>
                {tab === 'tab-1' && (
                    <Button
                        className="ml-1"
                        variant="primary"
                        type="submit"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={() => {
                            setTab('tab-2');
                        }}
                        disabled={
                            !selectedPaymentMethod.code || isButtonLoading
                        }
                    >
                        Avançar
                    </Button>
                )}
                {tab === 'tab-2' && (
                    <Button
                        className="ml-1"
                        variant="primary"
                        type="submit"
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                        onClick={handleSubmit}
                        disabled={isButtonLoading}
                    >
                        {isButtonLoading ? (
                            <Spinner animation="border" />
                        ) : (
                            'Salvar'
                        )}
                    </Button>
                )}
            </ModalFooter>
        </Modal>
    );
}
