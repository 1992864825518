/* eslint-disable prefer-destructuring */
import React from 'react';
import { Form, Card } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useOnlineStore } from '../../../contexts/onlineStoreContext';
import { StyledH4 } from './styles';
import { StyledCard } from '../../../styles/card';
import { getSelectedSeller } from '../../../utils/getSelectedSeller';
import ProductHttpService from '../../../services/http/product.http';

document.addEventListener('keydown', (event: any) => {
    if (
        event.key === 'Enter' &&
        (event.target.nodeName === 'INPUT' ||
            event.target.nodeName === 'SELECT')
    ) {
        const form = event.target.form;
        const index = Array.prototype.indexOf.call(form, event.target);

        if (index + 1 >= 3) {
            return;
        }

        form.elements[index + 1].focus();
        event.preventDefault();
    }
});

const InitialInformations: React.FC = () => {
    const { setOption } = useOnlineStore();

    const { register, watch, setValue } = useForm();
    const formData = watch();

    const selectedSeller = getSelectedSeller();

    async function loadModules() {
        try {
            const modules: any = await ProductHttpService.getModules({
                active: true,
                isOnlineStore: true,
            });

            return modules.data.data;
        } catch (err) {
            return [];
        }
    }

    async function loadRoofTypes() {
        try {
            const roofTypes: any = await ProductHttpService.getRoofTypes({
                moduleId: formData?.moduleId,
                active: true,
                isOnlineStore: true,
            });

            return roofTypes.data;
        } catch (err) {
            return [];
        }
    }

    async function loadInverterBrands() {
        try {
            const inverterBrand: any =
                await ProductHttpService.getInverterBrands({
                    active: true,
                    isOnlineStore: true,
                });

            return inverterBrand.data;
        } catch (err) {
            return [];
        }
    }

    async function loadInverter() {
        try {
            const inverter: any = await ProductHttpService.getInverters({
                inverterBrandId: formData?.inverterBrandId,
                active: true,
                isOnlineStore: true,
            });

            return inverter.data;
        } catch (err) {
            return [];
        }
    }

    const modules = useQuery(['modules', selectedSeller], loadModules);
    const inverterBrands = useQuery(
        ['inverterBrands', selectedSeller],
        loadInverterBrands,
    );
    const {
        data: roofTypes,
        refetch: refetchRoofTypes,
        isLoading: isLoadingModules,
    } = useQuery(
        ['roofTypes', selectedSeller],
        formData?.moduleId && modules.isSuccess && loadRoofTypes,
    );
    const {
        data: inverter,
        refetch: refetchInverter,
        isLoading: isLoadingInverter,
    } = useQuery(
        ['inverter', selectedSeller],
        formData?.inverterBrandId && inverterBrands.isSuccess && loadInverter,
    );

    return (
        <>
            <StyledH4>Informações iniciais</StyledH4>
            <p>
                Com os dados de potência, módulo e tipo de telhado, nossa
                plataforma irá montar um gerador personalizado para você.
            </p>
            <StyledCard>
                <Card.Body>
                    <Form>
                        <Form.Group controlId="formPower">
                            <Form.Label>Selecione a potência</Form.Label>
                            <Form.Control
                                ref={register}
                                name="power"
                                type="number"
                                max={200}
                                min={0}
                                onChange={() => {
                                    setOption(formData);
                                }}
                            />
                        </Form.Group>

                        <Form.Group controlId="formInverterBrandId">
                            <Form.Label>
                                Selecione a marca de inversor
                            </Form.Label>
                            <Form.Control
                                ref={register}
                                name="inverterBrandId"
                                type="text"
                                as="select"
                                onChange={async () => {
                                    setValue('inverterId', null);
                                    await refetchInverter();
                                    setOption(formData);
                                }}
                            >
                                <option value="">Selecione</option>
                                {inverterBrands &&
                                    inverterBrands.data?.data?.map(
                                        (brand: any, index: any) => (
                                            <option
                                                key={index}
                                                value={brand.id}
                                            >
                                                {brand.name}
                                            </option>
                                        ),
                                    )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formInverter">
                            <Form.Label>Selecione o inversor</Form.Label>
                            <Form.Control
                                ref={register}
                                name="inverterId"
                                type="text"
                                as="select"
                                onChange={async () => {
                                    setOption(formData);
                                }}
                                disabled={
                                    !formData?.inverterBrandId &&
                                    !isLoadingInverter
                                }
                            >
                                <option value="">Selecione</option>
                                {inverter &&
                                    !isLoadingInverter &&
                                    inverter?.data?.map(
                                        (inver: any, index: any) => (
                                            <option
                                                key={index}
                                                value={inver?.id}
                                            >
                                                {inver?.inverterName}
                                            </option>
                                        ),
                                    )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formModule">
                            <Form.Label>Selecione o módulo</Form.Label>
                            <Form.Control
                                ref={register}
                                name="moduleId"
                                type="text"
                                as="select"
                                onChange={async () => {
                                    // setValue('rooftTypeId', null);
                                    await refetchRoofTypes();
                                    setOption(formData);
                                }}
                            >
                                <option value="">Selecione</option>
                                {modules &&
                                    modules.data?.map(
                                        (module: any, index: any) => (
                                            <option
                                                key={index}
                                                value={module.id}
                                            >
                                                {module.name}
                                            </option>
                                        ),
                                    )}
                            </Form.Control>
                        </Form.Group>

                        <Form.Group controlId="formBasicRoofType">
                            <Form.Label>Selecione o tipo de telhado</Form.Label>
                            <Form.Control
                                ref={register}
                                name="roofTypeId"
                                type="text"
                                as="select"
                                disabled={
                                    !formData?.moduleId && !isLoadingModules
                                }
                                onChange={async () => {
                                    setOption(formData);
                                }}
                            >
                                <option value="">Selecione</option>
                                {roofTypes &&
                                    !isLoadingModules &&
                                    roofTypes?.data?.map(
                                        (roofType: any, index: any) => (
                                            <option
                                                key={index}
                                                value={roofType?.id}
                                            >
                                                {roofType?.name}
                                            </option>
                                        ),
                                    )}
                            </Form.Control>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </StyledCard>
        </>
    );
};

export default InitialInformations;
