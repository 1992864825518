import { AxiosResponse } from 'axios';
import { InvoiceTriangulationProps } from '../../components/Triangulation/InvoiceTriangulation';
import Request from './request';
import InvoiceAttachment from '../../shared/interfaces/invoiceAttachment.interface';
import RequestV2 from './request-v2.http';
import { InvoiceStatus } from '../../constants/invoiceStatus';

interface SaveAttachmentDTO {
    type: 'FINANCING' | 'OTHERS' | 'DEPOSIT';
    file: string | ArrayBuffer;
    fileExtension: string;
    voucherNumber: string | null;
    voucherDate: string | null;
    voucherAmount: number | null;
    bankAccount?: number;
}

interface ValidateInvoiceDTO {
    discount: number;
    deliveryDate: string;
    isFutureSale: boolean;
    isTriangulation: boolean;
    feedback: string;
}

export default class InvoiceHttpService {
    private static uri = 'invoices';

    public static uriV2 = 'invoice';

    // V2

    public static createBaseCart() {
        return RequestV2.post(`${this.uriV2}/cart`);
    }

    public static addByStructureCalculationKit(item: any) {
        return RequestV2.post(`${this.uriV2}/cart/structure`, item);
    }

    public static getProducts(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}/products`);
    }

    public static updateItemQuantity(
        id: number,
        code: string,
        quantity: number,
        availability: string,
    ) {
        return RequestV2.patch(
            `${this.uriV2}/cart/${id}/item/${code}/quantity/${quantity}?availability=${availability}`,
        );
    }

    public static addItemToCart(
        code: string,
        quantity: number,
        availability: string,
    ) {
        return RequestV2.post(`${this.uriV2}/cart/item/`, {
            erpCode: code,
            quantity,
            availability,
        });
    }

    public static fixItemToCart(
        data: Array<{ erpCode: string; quantity: number }>,
    ) {
        return RequestV2.patch(`${this.uriV2}/cart/fix/item/`, data);
    }

    public static addByKit(item: any) {
        return RequestV2.post(`${this.uriV2}/cart/options`, item);
    }

    public static emptyCart() {
        return RequestV2.del(`${this.uriV2}/cart`);
    }

    public static getUserCart() {
        return RequestV2.get(`${this.uriV2}/cart`);
    }

    public static changeCartType(type: string) {
        return RequestV2.patch(`${this.uriV2}/cart/change-cart-type/${type}`);
    }

    public static setStatus(id: string, data: any) {
        if (data.status === InvoiceStatus.Invoice) {
            return RequestV2.post(`${this.uriV2}/${id}`);
        }
        if (data.status === InvoiceStatus.Budget) {
            return RequestV2.post(`${this.uriV2}/budget/${id}`);
        }
        throw Error('Status não preparado para mudança');
    }

    public static index(params: {}) {
        return RequestV2.get(this.uriV2, params);
    }

    public static indexBudget(params: {}) {
        return RequestV2.get(`${this.uriV2}/budget`, params);
    }

    public static budgetItemSimulate(id: number, params: {}) {
        return RequestV2.patch(
            `${this.uriV2}/budget/${id}/item/simulate`,
            params,
        );
    }

    public static show(id: string) {
        return RequestV2.get(`${this.uriV2}/${id}`);
    }

    public static contributorLogs(id: number) {
        return RequestV2.get(`${this.uriV2}/log/${id}/contributor`);
    }

    public static logs(id: number) {
        return RequestV2.get(`${this.uriV2}/log/${id}`);
    }

    public static updateInsurance(id: string) {
        return RequestV2.patch(`${this.uriV2}/${id}/insurance`);
    }

    public static simulateUpdateDiscount(
        id: string,
        data: { discountValue: number },
    ) {
        return RequestV2.patch(`${this.uriV2}/${id}/discount/simulate`, data);
    }

    public static recalculate(id: string) {
        return RequestV2.patch(`${this.uriV2}/${id}/recalculate`);
    }

    public static updateCommission(id: number) {
        return RequestV2.patch(`${this.uriV2}/${id}/commission`);
    }

    public static canValidate(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}/validate`);
    }

    public static validate(id: number, data: ValidateInvoiceDTO) {
        return RequestV2.patch(`${this.uriV2}/${id}/confirm`, data);
    }

    public static validateCanAddAttachments(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}/can-add-attachments`);
    }

    public static calcFreightFretefyValue(id: string) {
        return RequestV2.patch(
            `${this.uriV2}/${id}/calc-freight-fretefy-value`,
        );
    }

    public static approve(id: number) {
        return RequestV2.patch(`${this.uriV2}/${id}/approve`);
    }

    public static reprove(id: string, data: { observation: string }) {
        return RequestV2.patch(`${this.uriV2}/${id}/reprove`, data);
    }

    public static invalidate(id: string, data: { feedback: string }) {
        return RequestV2.patch(`${this.uriV2}/${id}/invalidate`, data);
    }

    public static updateDeliveryDate(id: number) {
        return RequestV2.patch(`${this.uriV2}/${id}/delivery-date`);
    }

    public static async cancel(id: number, reason: any = {}) {
        return RequestV2.patch(`${this.uriV2}/${id}/cancel`, reason);
    }

    public static getInvoicesPaycredit(params: {}) {
        return RequestV2.get(`${this.uriV2}/listInvoicePaycredit`, params);
    }

    public static getInvoicesPaycreditLogs(params: {}) {
        return RequestV2.get(`${this.uriV2}/listInvoicePaycreditLogs`, params);
    }

    public static sendCreditCardAnalysis(body: {}) {
        return RequestV2.post(`${this.uriV2}/sendCreditCardAnalysis`, body);
    }

    public static checkCreditCard(invoicePaycredit: any) {
        return RequestV2.post(
            `${this.uriV2}/checkCreditCard`,
            invoicePaycredit,
        );
    }

    public static useSameAddressForDelivery(id: string) {
        return RequestV2.patch(
            `${this.uriV2}/${id}/use-same-address-for-delivery`,
        );
    }

    public static addAttachment(
        invoiceId: string,
        attachment: SaveAttachmentDTO,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return RequestV2.post(
            `${this.uriV2}/${invoiceId}/attachments`,
            attachment,
        );
    }

    public static async removeAttachment(
        invoiceId: string,
        attachmentId: number,
    ) {
        return RequestV2.del(
            `${this.uriV2}/${invoiceId}/attachments/${attachmentId}`,
        );
    }

    public static async remake(id: number, keepAttachments: boolean = false) {
        return RequestV2.get(`${this.uriV2}/${id}/remake`, {
            keepAttachments,
        });
    }

    public static selectOthersPaymentMethod(id: number, data: {}) {
        return RequestV2.patch(`${this.uriV2}/${id}/selectPaymentMethod`, data);
    }

    public static print(id: number, type: 'budget' | 'order') {
        return RequestV2.downloadFile(
            `${this.uriV2}/${id}/pdf`,
            undefined,
            type === 'budget' ? `Orçamento #${id}` : `Pediro #${id}`,
        );
    }

    public static getNfeSimpleShipment(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}/nfe-simple-shipment`);
    }

    public static getNfeFutureSale(id: number) {
        return RequestV2.get(`${this.uriV2}/${id}/nfe-future-sale`);
    }

    public static update(item: any) {
        return RequestV2.patch(`${this.uriV2}/cart/${item.id}`, item);
    }

    public static updateInvoiceCustomer(sellerId: string, status: string) {
        return RequestV2.patch(
            `${this.uriV2}/customer/integrator/${sellerId}/${status}`,
        );
    }

    public static async destroy(id: number) {
        return RequestV2.del(`${this.uriV2}/budget/${id}`);
    }

    public static getAttachments(
        invoiceId: string,
        params?: { take: number; skip: number },
    ): Promise<AxiosResponse<any>> {
        return RequestV2.get(`${this.uriV2}/${invoiceId}/attachments`, params);
    }

    public static confirmPayment(id: string, data: {}) {
        return RequestV2.patch(`${this.uriV2}/${id}/confirm-payment`, data);
    }

    public static addFinancing(item: any) {
        return RequestV2.post(`${this.uriV2}/financing`, item);
    }

    public static sendToFretefy(id: number) {
        return RequestV2.post(`${this.uriV2}/${id}/send-fretefy`);
    }

    public static downloadAttachment(id: number, exportFile?: boolean) {
        const url = `${this.uriV2}/0/attachments/${id}/download`;
        if (exportFile) {
            return RequestV2.getFile(url);
        }
        return RequestV2.downloadFile(url);
    }

    public static downloadPaymentInstruction() {
        return RequestV2.downloadFile(
            `${this.uriV2}/0/attachments/payment-instructions/download`,
        );
    }

    public static getAttachment(
        invoiceId: string,
        attachmentId: string,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return RequestV2.get(
            `${this.uriV2}/${invoiceId}/attachments/${attachmentId}`,
        );
    }

    public static updateAttachment(
        invoiceId: string,
        attachmentId: string,
        attachment: Omit<SaveAttachmentDTO, 'file' | 'fileExtension'>,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return RequestV2.put(
            `${this.uriV2}/${invoiceId}/attachments/${attachmentId}`,
            attachment,
        );
    }

    public static async updateAttachmentStatus(
        invoiceId: number,
        attachmentId: number,
    ): Promise<AxiosResponse<InvoiceAttachment>> {
        return RequestV2.patch(
            `${this.uriV2}/${invoiceId}/attachments/${attachmentId}/send`,
        );
    }

    public static budgetItems(id: number, params: any[]) {
        return RequestV2.patch(`${this.uriV2}/budget/${id}/item`, params);
    }

    //= =============================

    /**
     * @deprecated
     */
    public static showTriangulation(id: string) {
        return Request.get(`${this.uri}/${id}/triangulation`);
    }

    /**
     * @deprecated
     */
    public static createTriangulation(
        id: string,
        invoiceTriangulation: InvoiceTriangulationProps,
    ) {
        return Request.post(
            `${this.uri}/${id}/triangulation`,
            invoiceTriangulation,
        );
    }

    /**
     * @deprecated
     */
    public static updateTriangulation(
        id: string,
        invoiceTriangulation: InvoiceTriangulationProps,
    ) {
        return Request.patch(
            `${this.uri}/${id}/triangulation`,
            invoiceTriangulation,
        );
    }

    /**
     * @deprecated
     */
    public static approveTriangulation(id: string) {
        return Request.patch(`${this.uri}/${id}/triangulation/approve`);
    }

    /**
     * @deprecated
     */
    public static getNfeTriangulation(id: number) {
        return Request.get(`${this.uri}/${id}/triangulation/nf`);
    }
}
