/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-destructuring */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable object-shorthand */
/* eslint-disable @typescript-eslint/array-type */
import { useEffect, useState } from 'react';
import { Col, Form, Spinner } from 'react-bootstrap';
import { DateTime } from 'luxon';
import CurrencyInput from 'react-currency-input-field';
import { formatCurrency } from '../../../../../utils/strings';
import DatePicker from '../../../../DatePicker';

interface IPropsDetailPaymentMethod {
    selectedPaymentMethod: {
        code: string;
        description: string;
        quantityInstallment: number;
        rangeInstallment: number;
        installments: Array<{
            value: number;
            dueDate: Date;
            numberInstallment: number;
        }>;
    };
    totalValue: number;
    isLoading?: boolean;
    onChange: (data: any) => void;
}

const DetailPaymentMethod = ({
    selectedPaymentMethod,
    isLoading,
    totalValue,
    onChange,
}: IPropsDetailPaymentMethod) => {
    const [installments, setInstallments] = useState<Array<any>>([]);

    function handleChangeInstallment(
        params: {
            value: string;
            dueDate: Date;
        },
        index: number,
    ) {
        const newInstallments = installments.map((installment, i) => {
            if (i === index)
                return {
                    ...installment,
                    value: params.value,
                    dueDate: params.dueDate,
                };

            return installment;
        });
        setInstallments(newInstallments);
        onChange({ ...selectedPaymentMethod, installments: newInstallments });
    }

    useEffect(() => {
        if (isLoading || !totalValue) return;

        const valueInstallments =
            totalValue / selectedPaymentMethod.quantityInstallment;
        const installmentsValue = [];

        for (
            let index = 0;
            index < selectedPaymentMethod.quantityInstallment;
            index += 1
        ) {
            const valueDifference =
                totalValue -
                parseFloat(valueInstallments.toFixed(2)) *
                    selectedPaymentMethod.quantityInstallment;
            installmentsValue.push({
                numberInstallment: index + 1,
                value:
                    index === 0
                        ? (
                              parseFloat(valueInstallments.toFixed(2)) +
                              valueDifference
                          ).toFixed(2)
                        : valueInstallments.toFixed(2),
                dueDate: selectedPaymentMethod.rangeInstallment
                    ? new Date(
                          DateTime.now()
                              .plus({
                                  days:
                                      selectedPaymentMethod.rangeInstallment *
                                      (index + 1),
                              })
                              .toFormat('MM/dd/yyyy'),
                      )
                    : new Date(),
            });
        }

        onChange({ ...selectedPaymentMethod, installments: installmentsValue });
        setInstallments(installmentsValue);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedPaymentMethod?.code, totalValue]);

    return (
        <>
            <Form.Row
                style={{
                    marginRight: '-15px !important',
                    overflowY: isLoading ? 'visible' : 'auto',
                    maxHeight: '400px',
                }}
                className="mt-3 ml-2 mb-4"
            >
                <Col lg={6} md={12}>
                    <Form.Group controlId="formBasicDescription">
                        <Form.Label>Descrição</Form.Label>
                        <Form.Control
                            value={selectedPaymentMethod.description}
                            name="description"
                            type="text"
                            disabled
                        />
                    </Form.Group>
                </Col>
                {totalValue && (
                    <Col lg={6} md={12}>
                        <Form.Group controlId="formBasicTotalValue">
                            <Form.Label>Valor total</Form.Label>
                            <Form.Control
                                value={formatCurrency(totalValue)}
                                name="totalValue"
                                type="text"
                                disabled
                            />
                        </Form.Group>
                    </Col>
                )}
                <Col lg={12} md={12}>
                    <hr />
                    <h5>Parcelas</h5>
                </Col>
                {isLoading || !installments ? (
                    <div
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <Spinner className="mr-4 mt-4" animation="border" />
                    </div>
                ) : (
                    installments.map((installment, index) => {
                        return (
                            <>
                                <Col lg={2} md={12}>
                                    <Form.Group controlId="formBasicNumber">
                                        {index === 0 && (
                                            <Form.Label>Número</Form.Label>
                                        )}
                                        <Form.Control
                                            name="numberInstallment"
                                            value={
                                                installment.numberInstallment
                                            }
                                            type="number"
                                            className="form-control-nexen"
                                            disabled
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={5} md={12}>
                                    <Form.Group controlId="formBasicValue">
                                        {index === 0 && (
                                            <Form.Label>Valor</Form.Label>
                                        )}
                                        <Form.Control
                                            as={CurrencyInput}
                                            name="value"
                                            key={`value-${index}`}
                                            type="text"
                                            decimalScale={2}
                                            value={installment.value}
                                            onValueChange={(
                                                newValue: string,
                                            ) => {
                                                handleChangeInstallment(
                                                    {
                                                        value: newValue || '',
                                                        dueDate:
                                                            installment.dueDate,
                                                    },
                                                    index,
                                                );
                                            }}
                                            intlConfig={{
                                                locale: 'pt-BR',
                                                currency: 'BRL',
                                            }}
                                            required
                                        />
                                    </Form.Group>
                                </Col>
                                <Col lg={5} md={12}>
                                    <Form.Group controlId="formBasicDueDate">
                                        {index === 0 && (
                                            <Form.Label>Vencimento</Form.Label>
                                        )}
                                        <DatePicker
                                            name="dueDate"
                                            key={`dueDate-${index}`}
                                            onSelect={(data: Date) =>
                                                handleChangeInstallment(
                                                    {
                                                        value: installment.value,
                                                        dueDate: data,
                                                    },
                                                    index,
                                                )
                                            }
                                            value={installment.dueDate}
                                            required
                                            minDate={new Date()}
                                        />
                                    </Form.Group>
                                </Col>
                            </>
                        );
                    })
                )}
            </Form.Row>
        </>
    );
};

export default DetailPaymentMethod;
