import { Form } from 'react-bootstrap';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { UseFormMethods, Controller } from 'react-hook-form';
import { InvoiceValidationFields } from '../InvoiceValidationForm';

interface DeliveryDateInputProps {
    form: UseFormMethods<InvoiceValidationFields>;
    disabled: boolean;
    minDeliveryDate: Date;
    datesWithQuantity: Array<{ date: Date; quantity: number }>;
}

export default function DeliveryDateInput({
    form,
    disabled,
    minDeliveryDate,
    datesWithQuantity,
}: DeliveryDateInputProps) {
    const isDateWithQuantity = (
        date: Date,
    ): {
        findDate: boolean;
        quantity: number;
    } => {
        const findDate = datesWithQuantity.find(
            (specificDate) =>
                date.getDate() === specificDate.date.getDate() &&
                date.getMonth() === specificDate.date.getMonth() &&
                date.getFullYear() === specificDate.date.getFullYear(),
        );
        return {
            findDate: !!findDate,
            quantity: findDate?.quantity ?? 0,
        };
    };

    const renderDayContents: ReactDatePickerProps['renderDayContents'] = (
        day,
        date,
    ) => {
        const dateWithQuantity = isDateWithQuantity(date);
        if (dateWithQuantity.findDate) {
            return (
                <>
                    <span>{day}</span>
                    <br />
                    <span style={{ fontSize: '10px', color: 'red' }}>
                        {dateWithQuantity.quantity}
                    </span>{' '}
                </>
            );
        }
        return day;
    };

    const filterDate = (date: Date) => {
        const dateWithQuantity = isDateWithQuantity(date);
        return dateWithQuantity.quantity > 0;
    };

    return (
        <Form.Group controlId="formBasicDeliveryDate">
            <Form.Label>Previsão de entrega</Form.Label>
            <Controller
                control={form.control}
                name="deliveryDate"
                render={({ value, onChange, onBlur }) => (
                    <ReactDatePicker
                        className="form-control"
                        onChange={onChange}
                        onBlur={onBlur}
                        onSelect={onChange}
                        selected={value}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="DD/MM/AAAA"
                        minDate={minDeliveryDate}
                        disabled={disabled}
                        renderDayContents={renderDayContents}
                        popperPlacement="bottom"
                        popperModifiers={{
                            flip: {
                                enabled: false,
                            },
                            preventOverflow: {
                                enabled: true,
                                boundariesElement: 'window',
                            },
                        }}
                        portalId="root"
                        filterDate={filterDate}
                    />
                )}
                rules={{
                    required: 'Campo obrigatório',
                }}
            />
            {form.errors.deliveryDate && (
                <Form.Text className="text-danger">
                    {form.errors.deliveryDate.message}
                </Form.Text>
            )}
        </Form.Group>
    );
}
