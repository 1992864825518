import { Spinner, Table } from 'react-bootstrap';
import { PaymentMethod } from '../../../constants/onlineStore';
import { formatCurrency } from '../../../utils/strings';
import StyledBoxDelivery from './styles';
import getMaxAvailabilityDate from '../../../utils/getMaxAvailabilityDate';
import { useAuth } from '../../../contexts/authContext';

interface InvoiceSummaryProps {
    invoice: any;
    showInstallmentRow?: boolean;
    loading?: boolean;
}

export default function InvoiceSummary({
    invoice,
    showInstallmentRow = true,
    loading = false,
}: InvoiceSummaryProps) {
    const { user } = useAuth();
    const installmentPaymentMethods = [
        PaymentMethod.CreditCard,
        PaymentMethod.CreditCardAndBarcode,
    ];

    const noDeliveryMessage =
        invoice.addressType === 'pickup'
            ? `Disponível para entrega em ${getMaxAvailabilityDate(invoice)}`
            : 'Prazo de entrega indisponível';

    return (
        <>
            <h5 className="mt-4">
                <strong>Resumo do pedido</strong>
            </h5>
            <Table size="sm" className="mt-2">
                <tbody>
                    <tr>
                        <td className="text-left">Subtotal</td>
                        <td className="text-right">
                            {formatCurrency(Number(invoice.rawInvoice))}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Comissão</td>
                        <td className="text-right">
                            {formatCurrency(Number(invoice.grossCommission))}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Acréscimos</td>
                        <td className="text-right">
                            {loading ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                formatCurrency(Number(invoice.interestValue))
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td className="text-left">Desconto</td>
                        <td className="text-right">
                            {loading ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                formatCurrency(Number(invoice.discountValue))
                            )}
                        </td>
                    </tr>
                    {showInstallmentRow &&
                        installmentPaymentMethods.includes(
                            invoice.paymentMethod,
                        ) && (
                            <tr>
                                <td>Parcelamento</td>
                                <td className="text-right">
                                    <strong>
                                        {invoice.cardMaxInstallmentNumber}x{' '}
                                    </strong>
                                </td>
                            </tr>
                        )}
                    {invoice.addressType !== 'pickup' && (
                        <tr>
                            <td className="text-left">Frete</td>
                            <td className="text-right">
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    formatCurrency(invoice.freightValue)
                                )}
                            </td>
                        </tr>
                    )}
                    <tr>
                        <td className="text-left">Total</td>
                        <td className="text-right">
                            <strong>
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    formatCurrency(invoice.totalInvoice)
                                )}
                            </strong>
                        </td>
                    </tr>
                    {user.canViewMarginLiquid && invoice.marginLiquid && (
                        <tr>
                            <td className="text-left">
                                Margem liquida de produto
                            </td>
                            <td className="text-right">
                                {loading ? (
                                    <Spinner animation="border" size="sm" />
                                ) : (
                                    `${invoice.marginLiquid.toFixed(2)}%`
                                )}
                            </td>
                        </tr>
                    )}
                </tbody>
            </Table>
            <StyledBoxDelivery className="text-center">
                <p>
                    <strong>
                        {invoice.deliveryDeadline &&
                        invoice.addressType === 'pickup'
                            ? `Previsão de entrega: ${invoice.deliveryDeadline} dias úteis`
                            : noDeliveryMessage}
                    </strong>
                </p>
            </StyledBoxDelivery>
        </>
    );
}
