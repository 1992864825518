import {
    budgetsListRoute,
    creditCardAnalysisListRoute,
    dashboardListRoute,
    invoicesListRoute,
    listGeneratorsRoute,
} from '../routes/config';
import userHasRoles from '../utils/userHasRoles';
import { Role } from '../constants/role';

export interface Option {
    icon: string;
    text: string;
    path: string;
}

export default class MenuService {
    public static get(user: any) {
        const menu: Option[] = [];

        if (!user) {
            return menu;
        }

        const home: Option = {
            icon: 'PiHouse',
            text: 'Início',
            path: dashboardListRoute.path,
        };

        const onlineStore: Option = {
            icon: 'PiStorefront',
            text: 'Loja Online',
            path: listGeneratorsRoute.path,
        };

        const invoices: Option = {
            icon: 'PiPackage',
            text: 'Meus Pedidos',
            path: invoicesListRoute.path,
        };

        const budgets: Option = {
            icon: 'PiShoppingBagOpen',
            text: 'Orçamentos',
            path: budgetsListRoute.path,
        };

        // TODO: Descomentar para voltar a utilizar o modulo de pontos
        // const points: Option = {
        //     icon: 'PiTrophy',
        //     text: 'Pontos Nexen',
        //     path: pointGeneralInfoRoute.path,
        // };

        const creditAnalysis: Option = {
            icon: 'PiCheckSquareOffset',
            text: 'Análise Cartão de Crédito',
            path: creditCardAnalysisListRoute.path,
        };

        menu.push(home);

        const sharedRoles = [
            Role.Administrator,
            Role.CommercialSupervisor,
            Role.Commercial,
            Role.CustomerSuccess,
            Role.Integrator,
            Role.Manager,
        ];

        if (userHasRoles(user, [...sharedRoles, Role.Contributor])) {
            menu.push(onlineStore);
        }

        if (
            userHasRoles(user, [
                ...sharedRoles,
                Role.Financial,
                Role.Contributor,
            ])
        ) {
            menu.push(invoices);
        }

        if (userHasRoles(user, [...sharedRoles, Role.Contributor])) {
            menu.push(budgets);
        }

        // TODO: Descomentar para voltar a utilizar o modulo de pontos
        // if (
        //     userHasRoles(user, [...sharedRoles, Role.PointsAdministrator]) ||
        //     (!userHasRoles(user, [Role.Contributor, Role.Financial]) &&
        //         user?.seller?.pointsEnabled)
        // ) {
        //     menu.push(points);
        // }

        if (userHasRoles(user, [Role.Administrator, Role.Financial])) {
            menu.push(creditAnalysis);
        }

        return menu;
    }
}
